import * as React from "react";
import { render } from 'react-dom';
import App from './App';
import SessionService from "./common/services/SessionService";
import WebAccessService from "./common/services/WebAccessService";
import AppUtilityService from "./common/services/AppUtilityService";
import { hashHistory } from "react-router";

import { Provider } from 'react-redux';
import { store } from "./common/store/ConfigureStore";

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.compact.css';

import 'antd/dist/antd.css';
import "leaflet/dist/leaflet.css";
import './index.scss';
import TagManager from "react-gtm-module";

if (SessionService.loggedIn()) {
    WebAccessService.loadUserPermissions()
        .then(response => {
            WebAccessService.setUserPermissions(response.data)
            renderApp();
        })
        .catch(err => {
            if (err.response.status === 401) {
                SessionService.clearSessionData();
                renderApp();
                hashHistory.push('/login');
            } else {
                AppUtilityService.handleApiError(err);
            }
        })
} else {
    renderApp();
}

function renderApp() {
  const tagManagerArgs = {
    gtmId: "GTM-NKM8M8CQ"
  }
  TagManager.initialize(tagManagerArgs);
  render(<Provider store={store}><App /></Provider>, document.getElementById('app'));
}

if (module['hot']) {
    module['hot'].accept();
}
