export const REPORTS_MAPPING = {
  'AdnwReport': '/reports/adnw',
  'ByDate': '/reports/recon-summary/ByDate',
  'ByDateSinceLastIPR': '/reports/recon-summary-ipr/ByDateSinceLastIPR',
  'ByGT': '/reports/recon-summary/ByGT',
  'ByGTStatus': '/reports/game-terminal-status',
  'ByGame': '/reports/recon-summary/ByGame',
  'DealsAvailable': '/reports/deals/DealsAvailable',
  'DealsCompleted': '/reports/deals/DealsCompleted',
  'DealsInPlay': '/reports/deals/DealsInPlay',
  'GameSuggestions': '/reports/game-suggestions',
  'Location_Un_install_Alert_Report': '/reports/location-un-install-alert',
  'NH_Lucky7_Monthly_Financial_Report': '/reports/nh-lucky7-monthly-financial-report',
  'Quarter_Close_Status': '/reports/quarterClose/status',
  'SaturdaySales': '/reports/saturday-sales',
  'Sessions': '/reports/sessions',
  'Ticket_Sales_By_Game_Report': '/reports/ticket-sales-by-game',
  'Ticket_Sales_By_Date_Report': '/reports/ticket-sales-by-date',
  'View_Distributor_Deals_Delivered': '/reports/invoicing/distributor-deals-delivered',
  'View_Jurisdiction_Games_List': '/reports/north-dakota-games-list',
  'View_North_Dakota_Invoices': '/reports/invoicing/north-dakota-invoices',
  'View_Ohio_Electronic_Instant_Bingo': '/reports/invoicing/ohio-electronic-instant-bingo',
  'View_Ohio_Invoices': '/invoices',
  'View_Ohio_Quarterly': '/reports/invoicing/ohio-quarterly',
  'View_Sales_Tax': '/reports/sales-tax',
  'View_Weekly_Numbers': '/reports/invoicing/weekly-numbers',
};
