export type UserRoleState = Partial<{
  addNewUserRoleSuccess: any;
  assignRoleToUserSuccess: any;
  deleteMultipleUserRolesError: any;
  deleteSelectedUserRoleError: any;
  loadAllUserRolesError: any;
  loadSelectedRoleDetailsError: any;
  unarchiveRolesSuccess: any;
  unassignRoleFromUserSuccess: any;
  updateSelectedRolePermissionsSuccess: any;
  updateSelectedUserRoleSuccess: any;
  addNewUserRoleError: any;
  assignRoleToUserError: any;
  deleteMultipleUserRolesSuccess: any;
  deleteSelectedUserRoleSuccess: any;
  loadAllUserRolesSuccess: any;
  loadSelectedRoleDetailsSuccess: any;
  unarchiveRolesError: any;
  unassignRoleFromUserError: any;
  updateSelectedRolePermissionsError: any;
  updateSelectedUserRoleError: any;
  userRoleListFilters: any;
}>;

const initialState: UserRoleState = {};

const UserRoleReducer = (state:UserRoleState = initialState, action): UserRoleState => {
  switch (action.type) {
    case "USER_ROLE_LIST_FILTER_UPDATE":
      return { ...state, userRoleListFilters: action.payload };
    /**
     * Load all roles
     */
    case "LOAD_ALL_USER_ROLES_REQUEST":
      return { ...state, loadAllUserRolesSuccess: null, loadAllUserRolesError: null };
    case "LOAD_ALL_USER_ROLES_SUCCESS":
      return { ...state, loadAllUserRolesSuccess: action.payload, loadAllUserRolesError: null };
    case "LOAD_ALL_USER_ROLES_FAILURE":
      return { ...state, loadAllUserRolesSuccess: null, loadAllUserRolesError: action.payload };

    /**
     * Delete multiple user roles
     */
    case "DELETE_MULTIPLE_USER_ROLES_REQUEST":
      return { ...state, deleteMultipleUserRolesSuccess: null, deleteMultipleUserRolesError: null };
    case "DELETE_MULTIPLE_USER_ROLES_SUCCESS":
      return { ...state, deleteMultipleUserRolesSuccess: action.payload, deleteMultipleUserRolesError: null };
    case "DELETE_MULTIPLE_USER_ROLES_FAILURE":
      return { ...state, deleteMultipleUserRolesSuccess: null, deleteMultipleUserRolesError: action.payload };

    /**
     * Delete single user roles
     */
    case "DELETE_SELECTED_USER_ROLE_REQUEST":
      return { ...state, deleteSelectedUserRoleSuccess: null, deleteSelectedUserRoleError: null };
    case "DELETE_SELECTED_USER_ROLE_SUCCESS":
      return { ...state, deleteSelectedUserRoleSuccess: action.payload, deleteSelectedUserRoleError: null };
    case "DELETE_SELECTED_USER_ROLE_FAILURE":
      return { ...state, deleteSelectedUserRoleSuccess: null, deleteSelectedUserRoleError: action.payload };

    /**
     * add new role
     */
    case "ADD_NEW_USER_ROLE_REQUEST":
      return { ...state, addNewUserRoleSuccess: null, addNewUserRoleError: null };
    case "ADD_NEW_USER_ROLE_SUCCESS":
      return { ...state, addNewUserRoleSuccess: action.payload, addNewUserRoleError: null };
    case "ADD_NEW_USER_ROLE_FAILURE":
      return { ...state, addNewUserRoleSuccess: null, addNewUserRoleError: action.payload };

    /**
     * Load selected role details
     */
    case "LOAD_SELECTED_ROLE_DETAILS_REQUEST":
      return { ...state, loadSelectedRoleDetailsSuccess: null, loadSelectedRoleDetailsError: null };
    case "LOAD_SELECTED_ROLE_DETAILS_SUCCESS":
      return { ...state, loadSelectedRoleDetailsSuccess: action.payload, loadSelectedRoleDetailsError: null };
    case "LOAD_SELECTED_ROLE_DETAILS_FAILURE":
      return { ...state, loadSelectedRoleDetailsSuccess: null, loadSelectedRoleDetailsError: action.payload };

    case "UPDATE_SELECTED_ROLE_DETAILS_REQUEST":
      return { ...state, updateSelectedUserRoleSuccess: null, updateSelectedUserRoleError: null };
    case "UPDATE_SELECTED_ROLE_DETAILS_SUCCESS":
      return { ...state, updateSelectedUserRoleSuccess: action.payload, updateSelectedUserRoleError: null };
    case "UPDATE_SELECTED_ROLE_DETAILS_FAILURE":
      return { ...state, updateSelectedUserRoleSuccess: null, updateSelectedUserRoleError: action.payload };

    case "UPDATE_SELECTED_ROLE_PERMISSIONS_REQUEST":
      return { ...state, updateSelectedRolePermissionsSuccess: null, updateSelectedRolePermissionsError: null };
    case "UPDATE_SELECTED_ROLE_PERMISSIONS_SUCCESS":
      return { ...state, updateSelectedRolePermissionsSuccess: action.payload, updateSelectedRolePermissionsError: null };
    case "UPDATE_SELECTED_ROLE_PERMISSIONS_FAILURE":
      return { ...state, updateSelectedRolePermissionsSuccess: null, updateSelectedRolePermissionsError: action.payload };

    /**
     * User role assign/unassign
     */
    case "ASSIGN_ROLE_TO_USER_REQUEST":
      return { ...state, assignRoleToUserSuccess: null, assignRoleToUserError: null };
    case "ASSIGN_ROLE_TO_USER_SUCCESS":
      return { ...state, assignRoleToUserSuccess: action.payload, assignRoleToUserError: null };
    case "ASSIGN_ROLE_TO_USER_FAILURE":
      return { ...state, assignRoleToUserSuccess: null, assignRoleToUserError: action.payload };

    case "UNASSIGN_ROLE_FROM_USER_REQUEST":
      return { ...state, unassignRoleFromUserSuccess: null, unassignRoleFromUserError: null };
    case "UNASSIGN_ROLE_FROM_USER_SUCCESS":
      return { ...state, unassignRoleFromUserSuccess: action.payload, unassignRoleFromUserError: null };
    case "UNASSIGN_ROLE_FROM_USER_FAILURE":
      return { ...state, unassignRoleFromUserSuccess: null, unassignRoleFromUserError: action.payload };

    case "UNARCHIVE_ROLES_REQUEST":
      return { ...state, unarchiveRolesSuccess: null, unarchiveRolesError: null };
    case "UNARCHIVE_ROLES_SUCCESS":
      return { ...state, unarchiveRolesSuccess: action.payload, unarchiveRolesError: null };
    case "UNARCHIVE_ROLES_FAILURE":
      return { ...state, unarchiveRolesSuccess: null, unarchiveRolesError: action.payload };

    default:
      return state;
  }
}

export default UserRoleReducer;
