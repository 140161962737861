import WebAccessService from './WebAccessService';

class SessionService {

    private _userName: string;
    get UserName(): string {
        if (this._userName == null) {
            this._userName = this.getUserName();
        }
        return this._userName;
    }
    set UserName(value: string) {
        this._userName = value;
    }

    setSessionData = (loginData: any) => {
        if (loginData.isAuthenticated) {
            
            // Persist the user name in a cookie
            this._userName = loginData.userName;
            var appSessionData = {
                token: loginData.token,
                role: loginData.role,
                userName: loginData.userName,
                userId: loginData.userId
            }
            
            localStorage.setItem("pg_app_session", JSON.stringify(appSessionData));
            return true;
        }
        return false;

    }

    loggedIn = () => {
        const token = this.getToken()
        return !!token;
    }


    getToken = () => {
        var sessionData = JSON.parse(localStorage.getItem("pg_app_session"));
        if (sessionData) {
            return sessionData.token;
        } else {
            return null
        }
    }

    getRole = () => {
        var sessionData = JSON.parse(localStorage.getItem("pg_app_session"));
        if (sessionData) {
            return sessionData.role;
        } else {
            return null
        }
    }

    getUserName = () => {
        var sessionData = JSON.parse(localStorage.getItem("pg_app_session"));
        if (sessionData) {
            this._userName = sessionData.userName;
            return sessionData.userName;
        } else {
            return null
        }
    }

    getUserId = () => {
        var sessionData = JSON.parse(localStorage.getItem("pg_app_session"));
        if (sessionData) {         
            return sessionData.userId;
        } else {
            return null
        }
    }

    clearSessionData = () => {
        WebAccessService.setUserPermissions(null)
        localStorage.removeItem("pg_app_session");
    }
}
const sessionService = new SessionService();

export default sessionService;
