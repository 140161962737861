import * as React from "react";
import { isEqual } from "lodash";
import { FormComponentProps } from 'antd/lib/form/Form'

import AppUtilityService from "../../services/AppUtilityService";

import { Row, Col, Card, Form, Icon, Input, Button, Spin, Alert } from 'antd';
import { Link } from "react-router";
import sessionService from "../../services/SessionService";
import webAccessService from "../../services/WebAccessService";
const FormItem = Form.Item;

class ForgotPasswordComponent extends React.Component<IUserForgotPasswordProps & FormComponentProps, IUserForgotPasswordState> {
    constructor(props: IUserForgotPasswordProps & FormComponentProps) {
        super(props);
        this.state = {
            isLoading: false,
            forgotPasswordSuccess: false
        }
    }

    componentDidMount() {
        if (sessionService.loggedIn()) {
            webAccessService.resetSidebarFeatures();
        }
    }


    componentWillReceiveProps(nextProps: IUserForgotPasswordProps) {
        /**
         * Get user details success/error handle
         */
        if (nextProps.userForgotPasswordSuccess && !isEqual(this.props.userForgotPasswordSuccess, nextProps.userForgotPasswordSuccess)) {
            this.setState({ isLoading: false, forgotPasswordSuccess: true });
        }

        if (nextProps.userForgotPasswordError && !isEqual(this.props.userForgotPasswordError, nextProps.userForgotPasswordError)) {
            AppUtilityService.showMessageToast(nextProps.userForgotPasswordError.response.data, "error");
            this.setState({ isLoading: false, forgotPasswordSuccess: false });
        }
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Spin spinning={this.state.isLoading}>
                <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
                  <Col xs={{ span: 24 }} sm={{ span: 16 }} md={{ span: 16 }} lg={{ span: 10 }} xl={{ span: 6 }}>
                        <Card title="Forgot Password" bordered={false}>
                            {
                                this.state.forgotPasswordSuccess ?
                                    <React.Fragment>
                                        <br />
                                        <Alert
                                            message="Password reset"
                                            description="A reset link has been sent to your email"
                                            type="success"
                                            showIcon
                                        />
                                        <br />
                                    </React.Fragment> :
                                    <Form onSubmit={this.handleFormSubmit}>
                                        <FormItem label="Email">
                                            {getFieldDecorator('email', {
                                                rules: [
                                                    { required: true, message: 'Please input an email!' },
                                                    { type: 'email', message: 'The input is not valid email!' },
                                                ],
                                            })(
                                                <Input type="email" placeholder="Email" />
                                            )}
                                        </FormItem>
                                        <FormItem>
                                            <div>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    Submit
                                    </Button>
                                            </div>
                                        </FormItem>
                                    </Form>

                            }
                            <Link to="/login" className="btn btn-link">Back to Login</Link>
                        </Card>
                    </Col>
                </Row>
            </Spin>
        );
    }
    handleFormSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ isLoading: true, forgotPasswordSuccess: false });
                var formData = values;
                this.props.actions.forgotPassword(formData);
            }
        });
    }
} export default Form.create<IUserForgotPasswordProps & FormComponentProps>()(ForgotPasswordComponent);