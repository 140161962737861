import * as React from "react";
import { debounce, isEmpty, isEqual } from "lodash";
import { FormComponentProps } from 'antd/lib/form/Form'

import AppUtilityService from "../../services/AppUtilityService";

import { Row, Col, Card, Form, Icon, Input, Button, Spin } from 'antd';
import { Link, hashHistory } from "react-router";

const FormItem = Form.Item;

class ChangePasswordComponent extends React.Component<IUserChangePasswordProps & FormComponentProps, IUserChangePasswordState> {
    constructor(props: IUserChangePasswordProps & FormComponentProps) {
        super(props);
        this.state = {
            confirmDirty: false,
            isLoading: false
        }
    }

    componentWillReceiveProps(nextProps: IUserChangePasswordProps) {
        if (nextProps.userChangePasswordSuccess && !isEqual(this.props.userChangePasswordSuccess, nextProps.userChangePasswordSuccess)) {
            AppUtilityService.showNotificationToast("Password changed", "Password has been changed successfully", "success");
            this.setState({ confirmDirty: true, isLoading: false });
            hashHistory.push("/login");
        }

        if (nextProps.userChangePasswordError && !isEqual(this.props.userChangePasswordError, nextProps.userChangePasswordError)) {
            hashHistory.push("/dashboard");
            AppUtilityService.showNotificationToast("Error saving", "Error saving password", "error");
            this.setState({ confirmDirty: true, isLoading: false });
        }
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Spin spinning={this.state.isLoading}>
                <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
                  <Col xs={{ span: 24 }} sm={{ span: 16 }} md={{ span: 16 }} lg={{ span: 10 }} xl={{ span: 6 }}>
                        <Card title="Change Password" bordered={false}>
                            <Form onSubmit={this.handleFormSubmit}>
                                <FormItem label="Password">
                                    {getFieldDecorator('password', {
                                        rules: [
                                            { required: true, message: 'Please enter a new password!' },
                                            {
                                                validator: this.validateToNextPassword,
                                            },
                                        ],
                                    })(
                                        <Input prefix={<Icon type="lock" />} type="password" placeholder="New Password" />
                                    )}
                                </FormItem>
                                <FormItem label="Confirm Password">
                                    {getFieldDecorator('confirmPassword', {
                                        rules: [
                                            { required: true, message: 'Please confirm password!' },
                                            {
                                                validator: this.compareToFirstPassword,
                                            }
                                        ],
                                    })(
                                        <Input prefix={<Icon type="lock" />} type="password" placeholder="Confirm Password" onBlur={this.handleConfirmBlur} />
                                    )}
                                </FormItem>
                                <FormItem>
                                    <div>
                                        <Button
                                            type="primary"
                                            htmlType="submit">
                                            Save New Password
                                        </Button>
                                        <br />
                                        <Link to="/dashboard" className="btn btn-link">Cancel</Link>
                                    </div>
                                </FormItem>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Spin>
        );
    }

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value, isLoading: false });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('The passwords do not match!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirmPassword'], { force: true });
        }
        callback();
    };

    handleFormSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ confirmDirty: true, isLoading: true });
                var formData = values;
                this.props.actions.changePassword(formData);
            }
        });
    }
} export default Form.create<IUserChangePasswordProps & FormComponentProps>()(ChangePasswordComponent);