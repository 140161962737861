export type DropdownState = Partial<{
  loadAddressTypesDropdownDataError: any;
  loadAddressTypesDropdownDataSuccess: any;
  loadContactTypesDropdownDataError: any;
  loadContactTypesDropdownDataSuccess: any;
  loadDistributorsDropdownDataError: any;
  loadDistributorsDropdownDataSuccess: any;
  loadLocationsDropdownDataError: any;
  loadLocationsDropdownDataSuccess: any;
  loadLocationsTypesDropdownDataError: any;
  loadLocationsTypesDropdownDataSuccess: any;
  loadUserRolesDropdownDataError: any;
  loadUserRolesDropdownDataSuccess: any;
  loadZonesDropdownDataError: any;
  loadZonesDropdownDataSuccess: any;
  loadLocationInvoicingDistributorsDropdownDataError: DropdownObject[];
  loadLocationInvoicingDistributorsDropdownDataSuccess: any;
  loadInvoicingServicesDropdownDataError: DropdownObject[];
  loadInvoicingServicesDropdownDataSuccess: any;
}>;

const initialState: DropdownState = {};

const DropdownReducer = (state = initialState, action): DropdownState => {
    switch (action.type) {
        case "LOAD_USER_ROLES_DROPDOWN_DATA_REQUEST":
            return { ...state, loadUserRolesDropdownDataSuccess: null, loadUserRolesDropdownDataError: null };
        case "LOAD_USER_ROLES_DROPDOWN_DATA_SUCCESS":
            return { ...state, loadUserRolesDropdownDataSuccess: action.payload, loadUserRolesDropdownDataError: null };
        case "LOAD_USER_ROLES_DROPDOWN_DATA_FAILURE":
            return { ...state, loadUserRolesDropdownDataSuccess: null, loadUserRolesDropdownDataError: action.payload };

        case "LOAD_LOCATIONS_DROPDOWN_DATA_REQUEST":
            return { ...state, loadLocationsDropdownDataSuccess: null, loadLocationsDropdownDataError: null };
        case "LOAD_LOCATIONS_DROPDOWN_DATA_SUCCESS":
            return { ...state, loadLocationsDropdownDataSuccess: action.payload, loadLocationsDropdownDataError: null };
        case "LOAD_LOCATIONS_DROPDOWN_DATA_FAILURE":
            return { ...state, loadLocationsDropdownDataSuccess: null, loadLocationsDropdownDataError: action.payload };

        case "LOAD_LOCATIONS_WITH_DEMO_TOGGLE_DROPDOWN_DATA_REQUEST":
            return { ...state, loadLocationsDropdownDataSuccess: null, loadLocationsDropdownDataError: null };
        case "LOAD_LOCATIONS_WITH_DEMO_TOGGLE_DROPDOWN_DATA_SUCCESS":
            return { ...state, loadLocationsDropdownDataSuccess: action.payload, loadLocationsDropdownDataError: null };
        case "LOAD_LOCATIONS_WITH_DEMO_TOGGLE_DROPDOWN_DATA_FAILURE":
            return { ...state, loadLocationsDropdownDataSuccess: null, loadLocationsDropdownDataError: action.payload };

        case "LOAD_LOCATION_TYPES_DROPDOWN_DATA_REQUEST":
            return { ...state, loadLocationsTypesDropdownDataSuccess: null, loadLocationsTypesDropdownDataError: null };
        case "LOAD_LOCATION_TYPES_DROPDOWN_DATA_SUCCESS":
            return { ...state, loadLocationsTypesDropdownDataSuccess: action.payload, loadLocationsTypesDropdownDataError: null };
        case "LOAD_LOCATION_TYPES_DROPDOWN_DATA_FAILURE":
            return { ...state, loadLocationsTypesDropdownDataSuccess: null, loadLocationsTypesDropdownDataError: action.payload };

        case "LOAD_ZONES_DROPDOWN_DATA_REQUEST":
            return { ...state, loadZonesDropdownDataSuccess: null, loadZonesDropdownDataError: null };
        case "LOAD_ZONES_DROPDOWN_DATA_SUCCESS":
            return { ...state, loadZonesDropdownDataSuccess: action.payload, loadZonesDropdownDataError: null };
        case "LOAD_ZONES_DROPDOWN_DATA_FAILURE":
            return { ...state, loadZonesDropdownDataSuccess: null, loadZonesDropdownDataError: action.payload };

        case "LOAD_DISTRIBUTORS_DROPDOWN_DATA_REQUEST":
            return { ...state, loadDistributorsDropdownDataSuccess: null, loadDistributorsDropdownDataError: null };
        case "LOAD_DISTRIBUTORS_DROPDOWN_DATA_SUCCESS":
            return { ...state, loadDistributorsDropdownDataSuccess: action.payload, loadDistributorsDropdownDataError: null };
        case "LOAD_DISTRIBUTORS_DROPDOWN_DATA_FAILURE":
            return { ...state, loadDistributorsDropdownDataSuccess: null, loadDistributorsDropdownDataError: action.payload };

        case "LOAD_ADDRESS_TYPES_DROPDOWN_DATA_REQUEST":
            return { ...state, loadAddressTypesDropdownDataSuccess: null, loadAddressTypesDropdownDataError: null };
        case "LOAD_ADDRESS_TYPES_DROPDOWN_DATA_SUCCESS":
            return { ...state, loadAddressTypesDropdownDataSuccess: action.payload, loadAddressTypesDropdownDataError: null };
        case "LOAD_ADDRESS_TYPES_DROPDOWN_DATA_FAILURE":
            return { ...state, loadAddressTypesDropdownDataSuccess: null, loadAddressTypesDropdownDataError: action.payload };

        case "LOAD_CONTACT_TYPES_DROPDOWN_DATA_REQUEST":
            return { ...state, loadContactTypesDropdownDataSuccess: null, loadContactTypesDropdownDataError: null };
        case "LOAD_CONTACT_TYPES_DROPDOWN_DATA_SUCCESS":
            return { ...state, loadContactTypesDropdownDataSuccess: action.payload, loadContactTypesDropdownDataError: null };
        case "LOAD_CONTACT_TYPES_DROPDOWN_DATA_FAILURE":
            return { ...state, loadContactTypesDropdownDataSuccess: null, loadContactTypesDropdownDataError: action.payload };

        case "LOAD_LOCATION_INVOICING_DISTRIBUTORS_DROPDOWN_DATA_REQUEST":
            return { ...state, loadLocationInvoicingDistributorsDropdownDataSuccess: null, loadLocationInvoicingDistributorsDropdownDataError: null };
        case "LOAD_LOCATION_INVOICING_DISTRIBUTORS_DROPDOWN_DATA_SUCCESS":
            return { ...state, loadLocationInvoicingDistributorsDropdownDataSuccess: action.payload, loadLocationInvoicingDistributorsDropdownDataError: null };
        case "LOAD_LOCATION_INVOICING_DISTRIBUTORS_DROPDOWN_DATA_FAILURE":
            return { ...state, loadLocationInvoicingDistributorsDropdownDataSuccess: null, loadLocationInvoicingDistributorsDropdownDataError: action.payload };

        case "LOAD_INVOICING_SERVICES_DROPDOWN_DATA_REQUEST":
            return { ...state, loadInvoicingServicesDropdownDataSuccess: null, loadInvoicingServicesDropdownDataError: null };
        case "LOAD_INVOICING_SERVICES_DROPDOWN_DATA_SUCCESS":
            return { ...state, loadInvoicingServicesDropdownDataSuccess: action.payload, loadInvoicingServicesDropdownDataError: null };
        case "LOAD_INVOICING_SERVICES_DROPDOWN_DATA_FAILURE":
            return { ...state, loadInvoicingServicesDropdownDataSuccess: null, loadInvoicingServicesDropdownDataError: action.payload };

        default:
            return state;
    }
}

export default DropdownReducer;
