import { isEmpty, get, indexOf } from "lodash";
import axios from 'axios';

import { PermissionHelper } from './PermissionHelper';

import SessionService from './SessionService';
import AppConstants from '../AppConstants';
import { hashHistory } from 'react-router';

let permissionInstance = PermissionHelper.getInstance();

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

const baseUrl = process.env.REACT_APP_API_URL ?? "/api";

class WebAccessService {

  loadUserPermissions = () => {
    if (SessionService.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + SessionService.getToken()
    }
    return axios.get(`${baseUrl}/lookups/permission`,
      {
        headers: headers
      }
    );
  }

  setUserPermissions = (userPermissions) => {
    permissionInstance.userPermissions = userPermissions;
  }

  getUserPermissions = () => {
    return permissionInstance.userPermissions;
  }

  isAdminUser = () => {
    var role = SessionService.getRole();
    if (role === "Admin") {
      return true;
    } else {
      return false;
    }
  }

  hasPermissionToAccess = (moduleName: string, featureName: string) => {
    var permissions: Array<Permissions> = permissionInstance.userPermissions;
    if (SessionService.getRole() === "Admin") {
      return true;
    } else {
      if (permissions && !isEmpty(permissions)) {
        var permissionArr = get(permissions, moduleName);
        if (!isEmpty(permissionArr) && indexOf(permissionArr, featureName) !== -1) {
          return true;
        } else {
          return false;
        }
      }
      else
      {
        return false;
      }
    }
  }

  hasPermissionToAccessReports = () => {
    var permissions: Array<Permissions> = permissionInstance.userPermissions;
    if (SessionService.getRole() === "Admin") 
    {
      return true;
    }
    else
    {
      if (permissions && !isEmpty(permissions)) 
      {
        // Check for any reports permissions to render the area
        var permissionArr = get(permissions, "Reports");
        if (!isEmpty(permissionArr)) 
        {
          return true;
        } 
        else 
        {
          // Check for Quarter Close View access too
          var permissionsQC = get(permissions, "Quarter Close");
          if (!isEmpty(permissionsQC) && indexOf(permissionsQC, "View") !== -1)
          {
            return true;
          }
          else
          {
            return false;
          }
        }
      }
      else
      {
        return false;
      }
    }
  }

  buildSidebarFeatures = () => {
    var menuItems = [];
    if (AppConstants.appMenuItems && !isEmpty(AppConstants.appMenuItems)) {
      AppConstants.appMenuItems.forEach((obj) => {
        if (this.hasPermissionToAccess(obj.key, "View")) {
          menuItems.push(obj)
        }
      })
    }
    if (SessionService.getRole() === "Admin") {
      menuItems.push(
        {
          key: "Admin",
          subMenuItems: AppConstants.adminMenuItems
        }
      )
    }
    // switch (appType) {
    //   case "admin":
    //     if (SessionService.getRole() === "Admin") {
    //       menuItems = AppConstants.adminMenuItems;
    //     }
    //     break;
    //   case "app":
    //     if (AppConstants.appMenuItems && !isEmpty(AppConstants.appMenuItems)) {
    //       AppConstants.appMenuItems.forEach((obj) => {
    //         if (this.hasPermissionToAccess(obj.key, "View")) {
    //           menuItems.push(obj)
    //         }
    //       })
    //     }
    //     break;
    // }
    return menuItems;
  }

  resetSidebarFeatures = () => {
    var menuItems: Array<MenuItem> = this.buildSidebarFeatures();
    if (menuItems && !isEmpty(menuItems)) {
      hashHistory.push(menuItems[0].link);
    }
  }


}

const webAccessService = new WebAccessService();
export default webAccessService;
/*
[
    {
      "moduleId": 1,
      "permissionId": 0,
      "module": "Location",
      "permissions": []
    },
    {
      "moduleId": 2,
      "permissionId": 0,
      "module": "Dashboard",
      "permissions": []
    },
    {
      "moduleId": 3,
      "permissionId": 0,
      "module": "Charity",
      "permissions": []
    },
    {
      "moduleId": 4,
      "permissionId": 0,
      "module": "Zones",
      "permissions": [
        {
          "permissionId": 4,
          "name": "View",
          "isAllowed": false
        },
        {
          "permissionId": 5,
          "name": "Modify",
          "isAllowed": false
        },
        {
          "permissionId": 6,
          "name": "Add",
          "isAllowed": false
        },
        {
          "permissionId": 7,
          "name": "Delete",
          "isAllowed": false
        }
      ]
    },
    {
      "moduleId": 5,
      "permissionId": 0,
      "module": "Jurisdiction",
      "permissions": [
        {
          "permissionId": 4,
          "name": "View",
          "isAllowed": false
        },
        {
          "permissionId": 5,
          "name": "Modify",
          "isAllowed": false
        },
        {
          "permissionId": 6,
          "name": "Add",
          "isAllowed": false
        },
        {
          "permissionId": 7,
          "name": "Delete",
          "isAllowed": false
        }
      ]
    },
    {
      "moduleId": 6,
      "permissionId": 0,
      "module": "Contact",
      "permissions": []
    }
  ]
  */