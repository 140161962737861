import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as UserAccountActions from '../../actions/user/UserAccountActions';
import ForgotPasswordComponent from '../../components/user/ForgotPasswordComponent';
import { RootState } from '../../store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
    return {
        userForgotPasswordSuccess: state.UserAccountReducer.userForgotPasswordSuccess,
        userForgotPasswordError: state.UserAccountReducer.userForgotPasswordError,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(UserAccountActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordComponent)
