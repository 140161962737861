import * as React from 'react';
import { hashHistory } from 'react-router';
import BreadcrumbContainer from '../../containers/breadcrumbs/BreadcrumbContainer';

import { NotificationDrawerComponent } from './NotificationDrawerComponent';
import SessionService from '../../services/SessionService';

import { Layout, Icon, Row, Col, Avatar, Badge, Dropdown, Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';
const { Header } = Layout;

export class HeaderComponent extends React.Component<IHeaderProps, IHeaderState> {
  constructor(props: IHeaderProps) {
    super(props);
    this.state = {
      isNotificationsVisible: false,
    };
  }

  render() {
    const menu = (
      <Menu>
        <Menu.Item onClick={this.logout}>
          <a target="_blank">Logout</a>
        </Menu.Item>
        <Menu.Item onClick={this.changePassword}>
          <a target="_blank">Change Password</a>
        </Menu.Item>
      </Menu>
    );
    return (
      <Header className="pg-header">
        <Row>
          <Col lg={16} md={20} sm={18} xs={20}>
            <BreadcrumbContainer />
          </Col>
          <Col lg={8} md={4} sm={6} xs={4} style={{ textAlign: 'right' }}>
            <Dropdown trigger={['click']} overlay={menu}>
              <Avatar size="large" icon={<UserOutlined />} />
            </Dropdown>
          </Col>
        </Row>
        {this.state.isNotificationsVisible && <NotificationDrawerComponent closeNotificationsCallback={this.handleNotificationsClose} />}
      </Header>
    );
  }

  showNotifications = () => {
    this.setState({ isNotificationsVisible: true });
  };
  handleNotificationsClose = () => {
    this.setState({ isNotificationsVisible: false });
  };
  logout = () => {
    if (SessionService.loggedIn()) {
      SessionService.clearSessionData();
      this.props.actions.clearStore();
      hashHistory.push('/login');
    }
  };
  changePassword = () => {
    hashHistory.push('/changePassword');
  };
}
