import * as React from "react";
import { isEqual } from "lodash";
import SaveContactFormComponent from "./SaveContactFormComponent";
import AppUtilityService from "../../../common/services/AppUtilityService";
import WebAccessService from "../../../common/services/WebAccessService";
import AddContactUserModalContainer from "../../containers/contacts/AddContactUserModalContainer";
import { Link, hashHistory } from "react-router";
import { Card, Button, Row, Col, Avatar, Spin, Upload, Modal } from 'antd';
import SessionService from "../../../common/services/SessionService";
import ContactNotesCardContainer from "../../containers/contacts/ContactNotesCardContainer";
const confirm = Modal.confirm;

export class ContactDetailsComponent extends React.Component<IContactBasicDetailsCardProps, IContactBasicDetailsCardState> {
    private saveContactFormRef: any;

    constructor(props: IContactBasicDetailsCardProps) {
        super(props);
        this.state = {
            contactDetails: null,
            contactId: this.props.contactId,
            isLoading: false,
            isBasicDetailsEditMode: false,
            showSaveUserModal: false,
        }
    }

    componentDidMount() {
        if (this.props.contactId) {
            this.getContactDetails();
        }
    }

    componentWillReceiveProps(nextProps: IContactBasicDetailsCardProps) {
        if (nextProps.contactDetailsSuccess && !isEqual(this.props.contactDetailsSuccess, nextProps.contactDetailsSuccess)) {
            var responseData: any = nextProps.contactDetailsSuccess;
            this.setState({ contactDetails: responseData, isLoading: false })
        }

        if (nextProps.contactDetailsError && !isEqual(this.props.contactDetailsError, nextProps.contactDetailsError)) {
            this.setState({ isLoading: false })
            AppUtilityService.handleApiError(nextProps.contactDetailsError);
        }

        if (nextProps.updateContactBasicDetailsSuccess && !isEqual(this.props.updateContactBasicDetailsSuccess, nextProps.updateContactBasicDetailsSuccess)) {
            var responseData: any = nextProps.updateContactBasicDetailsSuccess;
            this.setState({ contactDetails: responseData, isBasicDetailsEditMode: false, isLoading: false });
            AppUtilityService.showMessageToast("Updated contact details", "success");
        }

        if (nextProps.updateContactBasicDetailsError && !isEqual(this.props.updateContactBasicDetailsError, nextProps.updateContactBasicDetailsError)) {
            this.setState({ isLoading: false })
            AppUtilityService.handleApiError(nextProps.updateContactBasicDetailsError);
        }


        if (nextProps.updateContactImageSuccess && !isEqual(this.props.updateContactImageSuccess, nextProps.updateContactImageSuccess)) {
            var responseData: any = nextProps.updateContactImageSuccess;
            var contactDetails = { ...this.state.contactDetails };
            contactDetails.image = responseData.blob;
            contactDetails.pictureId = responseData.documentId;
            this.setState({ contactDetails, isLoading: false });
            AppUtilityService.showMessageToast("User image updated successfully", "success");
        }

        if (nextProps.updateContactImageError && !isEqual(this.props.updateContactImageError, nextProps.updateContactImageError)) {
            this.setState({ isLoading: false })
            AppUtilityService.handleApiError(nextProps.updateContactImageError);
        }


        if (!isEqual(this.props.removeContactImageSuccess, nextProps.removeContactImageSuccess)) {
            var contactDetails = { ...this.state.contactDetails };
            contactDetails.image = null;
            this.setState({ contactDetails, isLoading: false });
        }

        if (nextProps.removeContactImageError !== null && !isEqual(this.props.removeContactImageError, nextProps.removeContactImageError)) {
            this.setState({ isLoading: false })
            if (nextProps.removeContactImageError) {
                AppUtilityService.handleApiError(nextProps.removeContactImageError);
            }
        }

        /**
         * Delete contact success/error handle
         */
        if (nextProps.deleteContactSuccess !== null && !isEqual(this.props.deleteContactSuccess, nextProps.deleteContactSuccess)) {
            this.setState({ isLoading: false })
            if (nextProps.deleteContactSuccess) {
                AppUtilityService.showMessageToast("Selected contact has been deleted", "success");
                hashHistory.push("/contacts");
            }
        }

        if (nextProps.deleteContactError && !isEqual(this.props.deleteContactError, nextProps.deleteContactError)) {
            this.setState({ isLoading: false })
            AppUtilityService.handleApiError(nextProps.deleteContactError);
        }

        if (nextProps.addContactNoteSuccess && !isEqual(this.props.addContactNoteSuccess, nextProps.addContactNoteSuccess)) {
            var responseData: any = nextProps.addContactNoteSuccess;
            this.getContactDetails();
        }

        if (nextProps.addContactNoteError && !isEqual(this.props.addContactNoteError, nextProps.addContactNoteError)) {
            this.setState({ isLoading: false })
            AppUtilityService.handleApiError(nextProps.addContactNoteError);
        }

        if (nextProps.deleteContactNoteSuccess && !isEqual(this.props.deleteContactNoteSuccess, nextProps.deleteContactNoteSuccess)) {
            var responseData: any = nextProps.addContactNoteSuccess;
            this.getContactDetails();
        }

        if (nextProps.deleteContactNoteError && !isEqual(this.props.deleteContactNoteError, nextProps.deleteContactNoteError)) {
            this.setState({ isLoading: false })
            AppUtilityService.handleApiError(nextProps.deleteContactNoteError);
        }

        if (nextProps.updateContactNoteSuccess && !isEqual(this.props.updateContactNoteSuccess, nextProps.updateContactNoteSuccess)) {
            var responseData: any = nextProps.updateContactNoteSuccess;
            this.getContactDetails();
        }

        if (nextProps.updateContactNoteError && !isEqual(this.props.updateContactNoteError, nextProps.updateContactNoteError)) {
            this.setState({ isLoading: false })
            AppUtilityService.handleApiError(nextProps.updateContactNoteError);
        }
    }

    render() {
        var cardActions = <div>
            {
                // WebAccessService.hasPermissionToAccess("Contact", "SendMail") &&
                // <Button type="primary" className="ml-8">Send mail</Button>
            }
            {
                WebAccessService.hasPermissionToAccess("Contact", "Modify") &&
                <Button className="ml-8" type="primary" onClick={this.editDetails}>Edit</Button>
            }
            {
                WebAccessService.hasPermissionToAccess("Contact", "AddUser") &&
                this.state.contactDetails && !this.state.contactDetails.user &&
                <Button className="ml-8" onClick={this.openSaveUserModal}>Create User</Button>
            }
            {
                WebAccessService.hasPermissionToAccess("Contact", "Delete") &&
                <Button className="ml-8" type="danger" onClick={this.deleteContact}>Delete</Button>
            }
        </div>;

        return (
            <Spin spinning={this.state.isLoading}>
                <Card className="content-card no-header-border" bordered={false}>
                    {
                        this.state.contactDetails && !this.state.isBasicDetailsEditMode &&
                        <Row gutter={24} style={{ paddingTop: "16px" }}>
                            <Col xl={3} lg={5}>
                                <div className="image-upload-container">
                                    <Upload
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        beforeUpload={this.beforeUploadHandler}
                                        onChange={this.handleFileUploadChange}
                                    >
                                        <div className="image-upload">
                                            <i className="icon-upload-photo" />
                                        </div>
                                    </Upload>
                                    <Avatar size={130}
                                        src={this.state.contactDetails.image ? AppUtilityService.getImageFromByteArray(this.state.contactDetails.image) : null}
                                    />
                                    {
                                        this.state.contactDetails.image &&
                                        <div className="action-btn">
                                            <span className="link-danger bold font-12" onClick={this.removeUserImage}>Remove</span>
                                        </div>
                                    }
                                </div>
                            </Col>
                            <Col xl={21} lg={19}>
                                <Row gutter={24}>
                                    <Col span={16}>
                                        <h1>{this.state.contactDetails && this.state.contactDetails.name}</h1>
                                    </Col>
                                    <Col span={8} className="text-right">
                                        {cardActions}
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col md={12} xl={8}>
                                        <Row>
                                            <Col span={24}>
                                                <div style={{ marginBottom: "16px" }}>
                                                    <span className="t-black bold">Email: </span>
                                                    <span className="t-black-light">{this.state.contactDetails.email}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <div style={{ marginBottom: "16px" }}>
                                                    <span className="t-black bold">Phone: </span>
                                                    <span className="t-black-light">{this.state.contactDetails.phone}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <div style={{ marginBottom: "16px" }}>
                                                    <span className="t-black bold">Address: </span>
                                                    <span className="t-black-light">  {
                                                        this.state.contactDetails && AppUtilityService.displayStringFromArray([
                                                            this.state.contactDetails.address1,
                                                            this.state.contactDetails.address2,
                                                            this.state.contactDetails.city,
                                                            this.state.contactDetails.addressState,
                                                            this.state.contactDetails.zipcode,
                                                        ])
                                                    }</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <div style={{ marginBottom: "16px" }}>
                                                    <span className="t-black bold">Job title: </span>
                                                    <span className="t-black-light">{this.state.contactDetails.jobTitle}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12} xl={8}>
                                        {
                                            this.state.contactDetails.location &&
                                            <Row>
                                                <Col span={24}>
                                                    <div style={{ display: "inline-block", verticalAlign: "top", marginTop: "2px" }}>
                                                        <Avatar size="small" shape="square" className="mr-8 avatar-square" style={{ backgroundColor: '#F8CA1C' }}>
                                                            <i className="icon-location" />
                                                        </Avatar>
                                                    </div>
                                                    <div style={{ display: "inline-block" }}>

                                                        {

                                                            WebAccessService.hasPermissionToAccess("Location", "View") ?
                                                                <Link to={"/locations/" + this.state.contactDetails.location.id}>
                                                                    <div className="link-primary font-18 bold" style={{ marginBottom: 0, lineHeight: "20px" }}>{this.state.contactDetails.location.name}</div>
                                                                </Link>
                                                                :
                                                                <div className="font-18 bold" style={{ marginBottom: 0, lineHeight: "20px" }}>{this.state.contactDetails.location.name}</div>
                                                        }
                                                        <div className="font-14 regular t-black-light">
                                                            {
                                                                AppUtilityService.displayStringFromArray([
                                                                    this.state.contactDetails.location.address1,
                                                                    this.state.contactDetails.location.address2,
                                                                    this.state.contactDetails.location.city,
                                                                    this.state.contactDetails.location.zipcode,
                                                                    this.state.contactDetails.location.addressState,
                                                                ])
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                        <br />
                                        {
                                            this.state.contactDetails.user &&
                                            <Row>
                                                <Col span={24}>
                                                    <div style={{ display: "inline-block", verticalAlign: "top", marginTop: "2px" }}>
                                                        <Avatar size="small" shape="square" className="mr-8 avatar-square" style={{ backgroundColor: '#F8CA1C' }}>
                                                            <i className="icon-user" />
                                                        </Avatar>
                                                    </div>
                                                    <div style={{ display: "inline-block" }}>
                                                        {
                                                            SessionService.getRole() === "Admin" ?
                                                                <Link to={"/admin/users/" + this.state.contactDetails.user.userId}>
                                                                    <div className="link-primary font-18 bold" style={{ marginBottom: 0, lineHeight: "20px" }}>User</div>
                                                                </Link>
                                                                :
                                                                <div className="font-18 bold" style={{ marginBottom: 0, lineHeight: "20px" }}>User</div>
                                                        }
                                                        <span className="t-black-light">Created on: {AppUtilityService.formatDate(this.state.contactDetails.user.created)}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                    {
                        this.state.contactDetails && this.state.isBasicDetailsEditMode &&
                        <Row>
                            <Col span={24}>
                                <SaveContactFormComponent ref={(ref) => this.saveContactFormRef = ref}
                                    isEditContact={true}
                                    contactDetails={this.state.contactDetails} />
                                <Row>
                                    <Col span={21} offset={3}>
                                        <Button className="mr-8" type="primary" htmlType="submit" onClick={this.handleUpdateContactSubmit}>Save</Button>
                                        <Button onClick={this.cancelEditDetails}> Cancel </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                </Card>

                {
                    this.state.showSaveUserModal &&
                    <AddContactUserModalContainer contactDetails={this.state.contactDetails}
                        userAddSuccessCallback={this.addUserSuccessHandler}
                        closeSaveUserModal={this.closeSaveUserModal} />
                }
                <ContactNotesCardContainer contactId={this.state.contactId} contactNotes={this.state.contactDetails?.notes}></ContactNotesCardContainer>
            </Spin>


        )
    }
    editDetails = () => {
        this.setState({ isBasicDetailsEditMode: true })
    }
    handleUpdateContactSubmit = (e) => {
        e.preventDefault();
        this.saveContactFormRef.validateFields((err, values) => {
            if (!err) {
                this.setState({ isLoading: true })
                var formData = values;
                formData.addressId = this.state.contactDetails.addressId;
                formData.charityId = this.state.contactDetails.charityId;
                this.props.actions.updateContactBasicDetails(this.state.contactDetails.id, formData)
            }
        })
    }
    cancelEditDetails = () => {
        this.setState({ isBasicDetailsEditMode: false })
    }
    getContactDetails = () => {
        var contactId = this.props.contactId;
        this.setState({ isLoading: true })
        this.props.actions.loadContactDetails(contactId);
    }
    /**
     * Save contact as user modal
     */
    openSaveUserModal = () => {
        this.setState({ showSaveUserModal: true })
    }
    closeSaveUserModal = () => {
        this.setState({ showSaveUserModal: false })
    }
    addUserSuccessHandler = (userDetails: UserDetail) => {
        hashHistory.push("/admin/users/" + userDetails.userId)
    }
    /**
  * Image upload / remove related
  */
    beforeUploadHandler = file => {
        return false;
    };

    handleFileUploadChange = info => {
        var { contactDetails } = this.state;
        if (info.file.status !== "removed") {
            this.setState({ isLoading: true })
            let files = new FormData();
            files.append(info.file.name, info.file);
            this.props.actions.updateContactImage(contactDetails.id, files);
        }
    };

    removeUserImage = () => {
        var { contactDetails } = this.state;
        confirm({
            title: 'Confirm dialog',
            content: 'Are you sure you want to remove contact image?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => {
                this.setState({ isLoading: true })
                this.props.actions.removeContactImage(contactDetails.id, contactDetails.pictureId)
            },
        });
    }

    /**
  * Delete contact
  */
    deleteContact = () => {
        var { contactDetails } = this.state;
        confirm({
            title: 'Confirm dialog',
            content: 'Are you sure you want to delete the selected contact?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => {
                this.setState({ isLoading: true })
                this.props.actions.deleteContactDetails(contactDetails.id)
            },
        });
    }
}
