import * as React from "react";
import { isEqual } from "lodash";
import { Link } from 'react-router';
import { FormComponentProps } from 'antd/lib/form/Form'

import AppUtilityService from "../../../common/services/AppUtilityService";
import SessionService from '../../../common/services/SessionService';
import WebAccessService from "../../services/WebAccessService";

import { Row, Col, Card, Form, Icon, Input, Button, Spin } from 'antd';
const FormItem = Form.Item;

class LoginComponent extends React.Component<IUserLoginProps & FormComponentProps, IUserLoginState> {
    constructor(props: IUserLoginProps & FormComponentProps) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        if (SessionService.loggedIn()) {
            WebAccessService.resetSidebarFeatures();
        }
    }


    componentWillReceiveProps(nextProps: IUserLoginProps) {
        /**
         * Get user details success/error handle
         */
        if (nextProps.userLoginSuccess && !isEqual(this.props.userLoginSuccess, nextProps.userLoginSuccess)) {
            var isAuthenticated = SessionService.setSessionData(nextProps.userLoginSuccess)
            if (isAuthenticated) {
                WebAccessService.loadUserPermissions()
                    .then(response => {
                        WebAccessService.setUserPermissions(response.data)
                        WebAccessService.resetSidebarFeatures()
                    })
                    .catch(err => {
                        AppUtilityService.handleApiError(err);
                    })

            } else {
                AppUtilityService.showMessageToast("Invalid credentials", "error")
            }
            this.setState({ isLoading: false })
        }

        if (nextProps.userLoginError && !isEqual(this.props.userLoginError, nextProps.userLoginError)) {
            this.setState({ isLoading: false })
            AppUtilityService.handleApiError(nextProps.userLoginError);
        }
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Spin spinning={this.state.isLoading}>
                <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
                  <Col xs={{ span: 24 }} sm={{ span: 16 }} md={{ span: 16 }} lg={{ span: 10 }} xl={{ span: 6 }}>
                        <Card title="Login" bordered={false}>
                            <Form onSubmit={this.handleFormSubmit}>
                                <FormItem label="Username">
                                    {getFieldDecorator('UserName', {
                                        rules: [{ required: true, message: 'Please input your username!' }],
                                    })(
                                        <Input prefix={<Icon type="user" />} placeholder="Username" />
                                    )}
                                </FormItem>
                                <FormItem label="Password">
                                    {getFieldDecorator('Password', {
                                        rules: [{ required: true, message: 'Please input your Password!' }],
                                    })(
                                        <Input.Password prefix={<Icon type="lock" />} placeholder="Password" />
                                    )}
                                </FormItem>
                                <FormItem>
                                    <div>                                        
                                        <Row>
                                            <Col span={12}>
                                                <Button type="primary" htmlType="submit">Log in</Button>
                                            </Col>
                                            <Col span={12} className="text-right">
                                                <Link to="/forgot-password" className="btn btn-link">Forgot Password</Link>
                                            </Col>
                                        </Row>
                                    </div>
                                </FormItem>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Spin>
        );
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ isLoading: true })
                var formData = values;
                this.props.actions.userLogin(formData);
            }
        });
    }
} export default Form.create<IUserLoginProps & FormComponentProps>()(LoginComponent);