
import * as React from "react";
import { Layout } from 'antd';
import ErrorBoundaryWrapperContainer from "../../containers/layouts/ErrorBoundaryWrapperContainer";
const { Content } = Layout;

export class LoginLayout extends React.Component<{}, {}> {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout style={{ minHeight: '100vh' }} className="login-layout">
        <ErrorBoundaryWrapperContainer>
          <Content>
            {this.props.children}
          </Content>
        </ErrorBoundaryWrapperContainer>
      </Layout>
    )
  }
}
