
import * as React from "react";
import { isEqual, lastIndexOf, includes, isEmpty, filter, find } from "lodash";
import { Link } from 'react-router';
import SessionService from "../../services/SessionService";
import WebAccessService from "../../services/WebAccessService";
import { Menu, Layout } from 'antd';
import config from "../../../../package.json";
const { Sider } = Layout;
const SubMenu = Menu.SubMenu;
const phLogo = require("../../../images/powerhouse-logo.png");
const arrowLogo = require("../../../images/arrow-logo.png");
const winWithArrow = require("../../../images/win-with-arrow.png");
const version = "Version " + config.version;

export class SidebarComponent extends React.Component<ISidebarProps, ISidebarState>{
  constructor(props: ISidebarProps) {
    super(props);
    this.state = {
      menuItems: [],
      currentRouteDetails: this.props.currentRouteDetails || null,
      selectedKeys: [],
      isSubMenuOpen: false
    }
  }

  componentWillMount() {
    this.buildSidebar();
  }

  componentWillReceiveProps(nextProps: ISidebarProps) {
    if (nextProps.currentRouteDetails && !isEqual(this.props.currentRouteDetails, nextProps.currentRouteDetails)) {
      var currentRouteDetails = nextProps.currentRouteDetails;

      var pathname = currentRouteDetails.pathname.substr(0, lastIndexOf(currentRouteDetails.pathname, "/"))
      if (pathname) {
        this.getSelectedKeys(pathname)
      }

      this.setState({ currentRouteDetails: currentRouteDetails })
    }
  }

  render() {
    const defaultOpenKeys = this.state.currentRouteDetails && this.state.currentRouteDetails.pathname && includes(this.state.currentRouteDetails.pathname, 'admin') ? 'subAdmin' : '';

    return (
      <Sider className="pg-sider" breakpoint='sm' collapsedWidth="0">
        <div className="logo">
          <img src={arrowLogo} width={46} height={46} />
          <div className="logo-separator" />
          <img src={phLogo} />
        </div>
        <Menu theme="dark" mode="inline"
          selectedKeys={this.state.selectedKeys}
          defaultOpenKeys={[defaultOpenKeys]}
        >
          {
            this.state.menuItems && !isEmpty(this.state.menuItems) &&
            this.state.menuItems.map((obj: MenuItem) => {
              return (
                obj.key === 'Admin' ?
                  <SubMenu key="subAdmin" title={obj.key} onTitleClick={this.toggleSubMenu}>
                    {
                      obj.subMenuItems && !isEmpty(obj.subMenuItems) &&
                      obj.subMenuItems.map((subMenuItem: MenuItem) => {
                        return (
                          <Menu.Item key={subMenuItem.key} onClick={() => this.getSelectedKeys(subMenuItem.link)}>
                            <Link title={subMenuItem.key} to={subMenuItem.link}></Link>
                            <i className={subMenuItem.icon} />
                            <span className="nav-text">{subMenuItem.title}</span>
                          </Menu.Item>
                        )
                      })
                    }
                  </SubMenu>
                  :
                  <Menu.Item key={obj.key} onClick={() => this.getSelectedKeys(obj.link)}>
                    <Link title={obj.title} to={obj.link}></Link>
                    <i className={obj.icon} />
                    <span className="nav-text">{obj.title}</span>
                  </Menu.Item>
              )
            })
          }
           <Menu.Divider/>
        </Menu>
        <Menu theme="dark" mode="inline">     
            <Menu.Item title={SessionService.UserName} disabled>{SessionService.UserName}</Menu.Item>
            <Menu.Item title={version} disabled>{version}</Menu.Item>
        </Menu>
        <img className={`${this.state.isSubMenuOpen ? 'hide-win-with-arrow' : 'win-with-arrow'}`} src={winWithArrow} />
      </Sider>
    )
  }

  toggleSubMenu = () => {
    this.setState(prevState => ({
      isSubMenuOpen: !prevState.isSubMenuOpen
    }));
  }

  buildSidebar = () => {
    var menuItems: Array<MenuItem> = WebAccessService.buildSidebarFeatures();
    this.setState({ menuItems }, () => {
      this.getSelectedKeys(this.state.currentRouteDetails.pathname)
    })
  }

  getSelectedKeys = (pathname) => {
    var item: MenuItem = filter(this.state.menuItems, (obj) => {
      if (obj.key !== 'Admin') {
        return includes(pathname, obj.link);
      }
    })[0];

    if (this.state.currentRouteDetails.pathname.includes('quarterClose') && !this.state.currentRouteDetails.pathname.includes(`reports/quarterClose`))
      {
          this.setState({ selectedKeys: ['Quarter Close'] });
      }
    else if (item) {
      this.setState({ selectedKeys: [item.key] })
    } else {
        var adminObject = find(this.state.menuItems, 'subMenuItems');
        var val: MenuItem = adminObject && filter(adminObject.subMenuItems, (subMenuItem) => {
        return includes(pathname, subMenuItem.link);
      })[0];
      if (val) {
        this.setState({ selectedKeys: [val.key] })
      }
    }
  }
}
