import * as React from "react";
import { Router, hashHistory } from 'react-router';
import routes from "./routes";
import AppUtilityService from "./common/services/AppUtilityService";

export default class App extends React.Component<{}, {}> {  
  render() {
    return <Router history={hashHistory} routes={routes} />
  }
}

hashHistory.listen(location => {
  let routeData = AppUtilityService.getRouteHistory();
  var tmpPrev = routeData.currentPath;
  if (location.key) {
    if (!routeData.currentPath) {
      routeData.currentPath = location.pathname
    } else {
      routeData.previousPath = tmpPrev;
      routeData.currentPath = location.pathname;
    }
    AppUtilityService.setRouteHistory(routeData)
  }
})