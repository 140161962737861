import * as React from "react";
import { debounce, isEqual, isEmpty, filter } from "lodash";
import AppUtilityService from "../../services/AppUtilityService";

import { Select, Spin, Tooltip } from 'antd';
const Option = Select.Option;

export class DropdownComponent extends React.Component<IDropdownProps, IDropdownState> {
  constructor(props: IDropdownProps) {
    super(props);
    this.state = {
      datasource: [],
      customParams: this.props.customParams || null,
      isLoading: false,
      searchTerm: this.props.preselectedSearchTerm || null,
      value: this.props.hasOwnProperty('value') && this.props.value ? this.props.value : undefined,
    }
    this.handleSearch = debounce(this.handleSearch, 800);
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    if (this.state.value) {
      this.handleOptimalDataLoad();
    }
  }

  componentWillReceiveProps(nextProps: IDropdownProps) {
    if (!isEqual(this.props.customParams, nextProps.customParams)) {
      this.setState({ customParams: nextProps.customParams, datasource: [] })
    }

    if (!isEqual(this.props.preselectedSearchTerm, nextProps.preselectedSearchTerm)) {
      this.setState({ searchTerm: nextProps.preselectedSearchTerm }, () => {
        this.loadData()
      })
    }

    if (nextProps.hasOwnProperty('value') && nextProps.value !== this.props.value) {
      var value = nextProps.value ? nextProps.value : undefined;
      this.setState({ value })
      if (!value) {
        this.resetDatasource();
      } else {
        this.handleOptimalDataLoad();
      }
    }

    if (nextProps.datasource && !isEqual(this.props.datasource, nextProps.datasource)) {
      var responseData: any = nextProps.datasource;
      this.setState({ datasource: responseData, isLoading: false })
    }

    if (nextProps.datasourceError && !isEqual(this.props.datasourceError, nextProps.datasourceError)) {
      this.setState({ isLoading: false })
      AppUtilityService.handleApiError(nextProps.datasourceError);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  render() {
    const datasource: DropdownObject[] = this.state.datasource.map(obj => ({
      id: this.getOptionId(obj),
      text: this.getOptionText(obj),
      isDisabled: this.props.disabledSelector?.(obj)
    }));
    if (this.props.prependExtraItem && isEmpty(filter(datasource, (obj: any) => { return obj.id === this.props.prependExtraItem.id }))) {
      datasource.unshift(this.props.prependExtraItem);
    }

    const children = datasource.map((obj: DropdownObject) => {
      return <Option
        key={!this.props.ignoreId ? obj.id?.toString() : obj.text}
        value={!this.props.ignoreId ? obj.id : obj.text}
        disabled={obj.isDisabled}
      >
        <Tooltip title={obj.text}>{obj.text}</Tooltip>
      </Option>;
    });
    return (
      <Select
        className={this.props.className}
        onDropdownVisibleChange={this.handleOptimalDataLoad}
        allowClear={this.props.allowClear === undefined || this.props.allowClear}
        showSearch
        style={this.props.disableMinWidth ? {} : { minWidth: "180px" }}
        onSearch={this.handleSearch}
        filterOption={false}
        onChange={this.handleSelect}
        placeholder={this.props.placeholder || null}
        value={this.state.value}
        notFoundContent={this.state.isLoading ? <Spin size="small" /> : null}
        mode="default"
        //showAllResultsWhenNothingAssigned={this.props.showAllResultsWhenNothingAssigned || false}
      >
        {children}
      </Select>
    );
  }

  handleOptimalDataLoad = () => {
    var { datasource } = this.state;
    if (isEmpty(datasource)) {
      this.loadData();
    }
  }


  handleSearch = (value) => {
    this.setState({ searchTerm: value }, () => {
      this.loadData();
    })
  }

  loadData = () => {
    var { searchTerm } = this.state;
    this.setState({ isLoading: true });
    var params = {
      searchTerm: searchTerm || null,
      showAllResultsWhenNothingAssigned: this.props.showAllResultsWhenNothingAssigned || false
    }
    params = { ...params, ...this.state.customParams };
    this.props.loadData(params, this.props.apiUrlId || null)

  }

  handleSelect = (value: number) => {
    if (this.props.getSelectedLabel) {
      var selectedObject = filter(this.state.datasource, (obj: DropdownObject) => {
        return this.getOptionId(obj) === value
      })[0];
      if (selectedObject) {
        this.props.getSelectedLabel(this.getOptionText(selectedObject));
      } else {
        this.props.getSelectedLabel(null);
      }
    }
    if (this.props.onChange) {
      this.props.onChange(value);
    }

    if (!value) {
      this.handleSearch(null)
    }
  }

  resetDatasource = () => {
    this.setState({ datasource: [] })
  }

  getOptionId = (obj: any) => {
    if (this.props.textSelector != null) {
      return this.props.idSelector(obj);
    }
    return obj.id;
  }

  getOptionText = (obj: any) => {
    if (this.props.textSelector != null) {
      return this.props.textSelector(obj);
    }
    return obj.text;
  }
}
