import { createFetched, Fetched } from '../../../common/store/fetched';
import { createSlice } from '@reduxjs/toolkit';
import { createFetchedSetter } from '../../../common/store/fetched.slices';
import {
  ArrowJurisdictionSitesOrganizations, LocationUninstallAlertReportItem,
  TicketSalesByDateReportChildItem,
  TicketSalesByDateReportItem,
  TicketSalesByGameReportChildItem,
  TicketSalesByGameReportItem,
} from './reporting.models';

export interface ReportingState {
  downloadNorthDakotaGameSuggestionsReportUpdated: Fetched<void>,
  downloadExcelTicketSalesByGameReportUpdated: Fetched<void>,
  downloadPdfTicketSalesByGameReportUpdated: Fetched<void>,
  ticketSalesByGameReportUpdated: Fetched<TicketSalesByGameReportItem[]>,
  ticketSalesByGameReportChildUpdated: Fetched<TicketSalesByGameReportChildItem[]>,
  ticketSalesByDateReportUpdated: Fetched<TicketSalesByDateReportItem[]>,
  ticketSalesByDateReportChildUpdated: Fetched<TicketSalesByDateReportChildItem[]>,
  arrowJurisdictionsSitesOrganizations: Fetched<ArrowJurisdictionSitesOrganizations>,
  locationUninstallAlertReportUpdated: Fetched<LocationUninstallAlertReportItem[]>,
  downloadExcelLocationUninstallAlertReportUpdated: Fetched<void>,
}

const initialState: ReportingState = {
  downloadNorthDakotaGameSuggestionsReportUpdated: createFetched(),
  downloadExcelTicketSalesByGameReportUpdated: createFetched(),
  downloadPdfTicketSalesByGameReportUpdated: createFetched(),
  ticketSalesByGameReportUpdated: createFetched(),
  ticketSalesByGameReportChildUpdated: createFetched(),
  ticketSalesByDateReportUpdated: createFetched(),
  ticketSalesByDateReportChildUpdated: createFetched(),
  arrowJurisdictionsSitesOrganizations: createFetched(),
  locationUninstallAlertReportUpdated: createFetched(),
  downloadExcelLocationUninstallAlertReportUpdated: createFetched(),
}

export const reportingSlice = createSlice({
  name: 'reporting',
  initialState,
  reducers: {
    downloadNorthDakotaGameSuggestionsReportUpdated: createFetchedSetter('downloadNorthDakotaGameSuggestionsReportUpdated'),
    ticketSalesByGameReportUpdated: createFetchedSetter('ticketSalesByGameReportUpdated'),
    ticketSalesByGameReportChildUpdated: createFetchedSetter('ticketSalesByGameReportChildUpdated'),
    ticketSalesByDateReportUpdated: createFetchedSetter('ticketSalesByDateReportUpdated'),
    ticketSalesByDateReportChildUpdated: createFetchedSetter('ticketSalesByDateReportChildUpdated'),
    downloadExcelTicketSalesByGameReportUpdated: createFetchedSetter('downloadExcelTicketSalesByGameReportUpdated'),
    downloadPdfTicketSalesByGameReportUpdated: createFetchedSetter('downloadPdfTicketSalesByGameReportUpdated'),
    arrowJurisdictionsSitesOrganizationsUpdated: createFetchedSetter('arrowJurisdictionsSitesOrganizations'),
    locationUninstallAlertReportUpdated: createFetchedSetter('locationUninstallAlertReportUpdated'),
    downloadExcelLocationUninstallAlertReportUpdated: createFetchedSetter('downloadExcelLocationUninstallAlertReportUpdated'),
  },
});
