import appUtilityService from "./services/AppUtilityService";
import { ISessionsListItem } from '../app/reducers/reports/ReportsReducer';
import moment from 'moment';

function eibReportHeaderRender(data) {
  return <div>Electronic Instant Bingo<br />{data.column.caption}</div>;
}

class AppConstants {
  timeStampFormat = "YYYY-MM-DD hh:mm";

  dateFormat: string = "MM/DD/YYYY";
  fullDateFormat: string = "dddd, MMMM D, YYYY";
  apiDateFormat: string = "YYYY-MM-DD";
  devexDate: string = "ddd, M/D";
  ndMaxGameSelectionsNumber: number = 12;
  ndMaxGameSelectionsString: string = "Twelve";
  gamePlayCashInMaxData: Number = 50;
  gamePlayCashInMetrics: Array<{ key: string; value: string; }> = [
    { value: "cash_in", key: "Cash In" },
    { value: "cash_net", key: "Cash Net" },
    { value: "played", key: "Gross Play" },
    { value: "adnw", key: "ADNW" }
  ];
  dashboardMapStatsMetrics: Array<{ key: string; value: string; }> = [
    { value: "cash_in", key: "Cash In" },
    { value: "play_count", key: "Play Count" },
    { value: "played", key: "Gross Play" }
  ];
  locationSummaryByGameMetrics: Array<{ key: string; value: string; }> = [
    { value: "playCount", key: "Play Count" },
    { value: "played", key: "Gross Play" },
    { value: "playCountByDenomination", key: "Denomination" }
  ];
  locationSummaryByDateMetrics: Array<{ key: string; value: string; }> = [
    { value: "cashIn", key: "Cash In" },
    { value: "cashNet", key: "Cash Net" },
    { value: "played", key: "Gross Play" },
    { value: "holdPercentage", key: "Hold Percentage" }
  ];
  reportDataCompareMetrics: Array<{ key: string; value: string; type: string }> = [
    { value: "cashIn", key: "Cash In", type: "currency" },
    { value: "cashNet", key: "Cash Net", type: "currency" },
    { value: "played", key: "Gross Play", type: "currency" },
    { value: "holdPercentage", key: "Hold Percentage", type: "percent" },
    { value: "playCount", key: "Play Count", type: "count" },
  ];
  adminMenuItems: Array<MenuItem> = [
    {
      key: "Zones",
      link: "/admin/zones",
      icon: "icon-zone",
      title: "Zone"
    },
    {
      key: "User Role",
      link: "/admin/user-role",
      icon: "icon-user-role",
      title: "User Role"
    },
    {
      key: "Users",
      link: "/admin/users",
      icon: "icon-user",
      title: "User"
    },
    {
      key: "Location Types",
      link: "/admin/locationTypes",
      icon: "icon-location-filled",
      title: "Location Types"
    },
    {
      key: "Games",
      link: "/admin/games",
      icon: "icon-games",
      title: "Games"
    },
    {
      key: "Game Selection Window",
      link: "/admin/gameSelectionWindow",
      icon: "icon-checkmark",
      title: "Game Selection Window"
    },
  ];
  appMenuItems: Array<MenuItem> = [
    {
      key: "Dashboard",  // (HAS TO MATCH MODULE NAME IN PERMISSIONS)
      link: "/dashboard",
      icon: "icon-dashboard",
      title: "Dashboard"
    },
    {
      key: "Location",
      link: "/locations",
      icon: "icon-location",
      title: "Locations"
    },
    {
      key: "Distributor",
      link: "/distributors",
      icon: "icon-distributor",
      title: "Distributors"
    },
    {
      key: "Contact",
      link: "/contacts",
      icon: "icon-contacts",
      title: "Contacts"
    },
    {
      key: "Charity",
      link: "/charities",
      icon: "icon-charity",
      title: "Charities"
    },
    {
      key: "Invoices",
      link: "/billing",
      icon: "icon-billing",
      title: "Billing"
    },
    {
      key: "Reports",
      link: "/reports",
      icon: "icon-reports",
      title: "Reports"
    },
    {
      key: "Quarter Close",
      link: "/quarterClose/locations",
      icon: "icon-dashboard",
      title: "Quarter Close"
    },
    {
      key: "LocationSettings",
      link: "/location-settings",
      icon: "icon-edit",
      title: "Location Settings"
    },
  ];

  reconSummaryReportColumns: any = [
    {
      reportType: "ByDate",
      columns: [
        { dataField: "lName", caption: "Location", format: "", dataType: "string", column: "lName", width: "35%" },
        { dataField: "lId", caption: "Location ID", format: "#", dataType: "number", column: "lId", valueFormat: "#", displayFormat: "{0}", width: "10%", excludeFromSummaryTotal: true },
        { dataField: "playCnt", caption: "Play Count", format: "#,##0", dataType: "number", column: "playCnt", valueFormat: "#,##0", displayFormat: "{0}", width: "10%" },
        { dataField: "cIn", caption: "Cash In", format: "$#,##0.00", dataType: "number", column: "cIn", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "cOut", caption: "Cash Out", format: "$#,##0.00", dataType: "number", column: "cOut", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "cNet", caption: "Cash Net", format: "$#,##0.00", dataType: "number", column: "cNet", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "played", caption: "Gross Play", format: "$#,##0.00", dataType: "number", column: "played", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "won", caption: "Won", format: "$#,##0.00", dataType: "number", column: "won", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "pwNet", caption: "Net Play", format: "$#,##0.00", dataType: "number", column: "pwNet", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "ppw", caption: "Payout", format: function (ppw) { return ppw + "%"; }, dataType: "number", valueFormat: { type: "fixedPoint", precision: 2 }, column: "ppw", displayFormat: "{0}%", width: "5%", excludeFromSummaryTotal: true },
        { dataField: "liability", caption: "Liability", format: "$#,##0.00", dataType: "number", column: "liability", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
      ],
      childColumns: [
        { dataField: "charityName", caption: "Charity", format: "", dataType: "string", column: "charityName", width: "15%" },
        { dataField: "sessionStart", caption: "Session Start", format: function (sessionStart) { return appUtilityService.formatTimeStampColumn(sessionStart) }, dataType: "date", column: "sessionStart", width: "10%" },
        { dataField: "sessionEnd", caption: "Session End", format: function (sessionEnd) { return appUtilityService.formatTimeStampColumn(sessionEnd) }, dataType: "date", column: "sessionEnd", width: "10%" },
        { dataField: "playCnt", caption: "Play Count", format: "#,##0", dataType: "number", column: "playCnt", valueFormat: "#,##0", displayFormat: "{0}", width: "10%" },
        { dataField: "cIn", caption: "Cash In", format: "$#,##0.00", dataType: "number", column: "cIn", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "cOut", caption: "Cash Out", format: "$#,##0.00", dataType: "number", column: "cOut", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "cNet", caption: "Cash Net", format: "$#,##0.00", dataType: "number", column: "cNet", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "played", caption: "Gross Play", format: "$#,##0.00", dataType: "number", column: "played", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "won", caption: "Won", format: "$#,##0.00", dataType: "number", column: "won", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "pwNet", caption: "Net Play", format: "$#,##0.00", dataType: "number", column: "pwNet", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "ppw", caption: "Payout", format: function (ppw) { return ppw + "%"; }, dataType: "number", valueFormat: { type: "fixedPoint", precision: 2 }, column: "ppw", displayFormat: "{0}%", width: "5%", excludeFromSummaryTotal: true },
        { dataField: "liability", caption: "Liability", format: "$#,##0.00", dataType: "number", column: "liability", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
      ],
    },
    {
      reportType: "ByGame",
      columns: [
        { dataField: "game", caption: "Game", format: "", dataType: "string", column: "game", width: "16.6%" },
        { dataField: "playCnt", caption: "Play Count", format: "#,##0", dataType: "number", column: "playCnt", valueFormat: "#,##0", displayFormat: "{0}", width: "16.6%" },
        { dataField: "played", caption: "Gross Play", format: "$#,##0.00", dataType: "number", column: "played", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "16.6%" },
        { dataField: "won", caption: "Won", format: "$#,##0.00", dataType: "number", column: "won", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "16.6%" },
        { dataField: "pwNet", caption: "Net Play", format: "$#,##0.00", dataType: "number", column: "pwNet", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "16.6%" },
        { dataField: "ppw", caption: "Payout", format: function (ppw) { return ppw + "%"; }, dataType: "number", valueFormat: { type: "fixedPoint", precision: 2 }, column: "ppw", displayFormat: "{0}%", width: "16.6%" },
      ],
      childColumns: [
        { dataField: "date", caption: "Date", format: function (date) { return appUtilityService.formatDevexColumnDate(date) }, dataType: "date", column: "date", width: "16.6%" },
        { dataField: "playCnt", caption: "Play Count", format: "#,##0", dataType: "number", column: "playCnt", valueFormat: "#,##0", displayFormat: "{0}", width: "16.6%" },
        { dataField: "played", caption: "Gross Play", format: "$#,##0.00", dataType: "number", column: "played", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "16.6%" },
        { dataField: "won", caption: "Won", format: "$#,##0.00", dataType: "number", column: "won", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "16.6%" },
        { dataField: "pwNet", caption: "Net Play", format: "$#,##0.00", dataType: "number", column: "pwNet", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "16.6%" },
        { dataField: "ppw", caption: "Payout", format: function (ppw) { return ppw + "%"; }, dataType: "number", valueFormat: { type: "fixedPoint", precision: 2 }, column: "ppw", displayFormat: "{0}%", width: "16.6%" },
      ],
    },
    {
      reportType: "ByGT",
      columns: [
        { dataField: "cName", caption: "Terminal", format: "", dataType: "string", column: "cName", width: "14.5%" },
        { dataField: "playCnt", caption: "Play Count", format: "#,##0", dataType: "number", column: "playCnt", valueFormat: "#,##0", displayFormat: "{0}", width: "9.5%" },
        { dataField: "played", caption: "Gross Play", format: "$#,##0.00", dataType: "number", column: "played", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "9.5%" },
        { dataField: "won", caption: "Won", format: "$#,##0.00", dataType: "number", column: "won", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "9.5%" },
        { dataField: "pwNet", caption: "Net Play", format: "$#,##0.00", dataType: "number", column: "pwNet", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "9.5%" },
        { dataField: "ppw", caption: "Payout", format: function (ppw) { return ppw + "%"; }, dataType: "number", valueFormat: { type: "fixedPoint", precision: 2 }, column: "ppw", displayFormat: "{0}%", width: "9.5%" },
        { dataField: "avgDailyNetWin", caption: "Avg Daily Net Win", format: "$#,##0.00", dataType: "number", valueFormat: "$#,##0.00", column: "avgDailyNetWin", displayFormat: "{0}", width: "9.5%" },
        { dataField: "cipupd", caption: "CIPUPD", format: "$#,##0.00", dataType: "number", valueFormat: "$#,##0.00", column: "cipupd", displayFormat: "{0}", width: "9.5%" },
        { dataField: "wipupd", caption: "WIPUPD", format: "$#,##0.00", dataType: "number", valueFormat: "$#,##0.00", column: "wipupd", displayFormat: "{0}", width: "9.5%" },
        { dataField: "averageBet", caption: "Average Bet", format: "$#,##0.00", dataType: "number", valueFormat: "$#,##0.00", column: "averageBet", displayFormat: "{0}", width: "9.5%" },
      ],
      childColumns: [
        { dataField: "date", caption: "Date", format: function (date) { return appUtilityService.formatDevexColumnDate(date) }, dataType: "date", column: "date", width: "16.6%" },
        { dataField: "playCnt", caption: "Play Count", format: "#,##0", dataType: "number", column: "playCnt", valueFormat: "#,##0", displayFormat: "{0}", width: "16.6%" },
        { dataField: "played", caption: "Gross Play", format: "$#,##0.00", dataType: "number", column: "played", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "16.6%" },
        { dataField: "won", caption: "Won", format: "$#,##0.00", dataType: "number", column: "won", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "16.6%" },
        { dataField: "pwNet", caption: "Net Play", format: "$#,##0.00", dataType: "number", column: "pwNet", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "16.6%" },
        { dataField: "ppw", caption: "Payout", format: function (ppw) { return ppw + "%"; }, dataType: "number", valueFormat: { type: "fixedPoint", precision: 2 }, column: "ppw", displayFormat: "{0}%", width: "16.6%" },
      ]
    }
  ];
  dealsReportColumns: any = [
    {
      reportType: "DealsAvailable",
      columns: [
        { dataField: "locationId", caption: "Location", format: "", dataType: "string", column: "locationId", width: "10%" },
        { dataField: "locationName", caption: "Name", format: "", dataType: "string", column: "locationName", width: "15%" },
        { dataField: "gameName", caption: "Game", format: "", dataType: "string", column: "gameName", width: "10%" },
        { dataField: "dateDelivered", caption: "Date Delivered", format: "", dataType: "string", column: "dateDelivered", width: "10%" },
        { dataField: "serial", caption: "Deal Serial #", format: "", dataType: "string", column: "serial", width: "10%" },
        { dataField: "form", caption: "Form", format: "", dataType: "string", column: "form", width: "5%" },
        { dataField: "mfd", caption: "Manufactured", format: "", dataType: "string", column: "mfd", width: "10%" },
        { dataField: "gameType", caption: "Type", format: "", dataType: "string", column: "gameType", width: "5%" },
        { dataField: "prchPrice", caption: "Price", format: "$#,##0.00", dataType: "number", column: "prchPrice", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "5%" },
        {}
      ],
    },
    {
      reportType: "DealsInPlay",
      columns: [
        { dataField: "locationId", caption: "Location", format: "", dataType: "string", column: "locationId", width: "10%" },
        { dataField: "locationName", caption: "Name", format: "", dataType: "string", column: "locationName", width: "15%" },
        { dataField: "gameName", caption: "Game", format: "", dataType: "string", column: "gameName", width: "10%" },
        { dataField: "dateInService", caption: "Date In Service", format: "", dataType: "string", column: "dateInService", width: "10%" },
        { dataField: "serial", caption: "Deal Serial #", format: "", dataType: "string", column: "serial", width: "10%" },
        { dataField: "form", caption: "Form", format: "", dataType: "string", column: "form", width: "5%" },
        { dataField: "mfd", caption: "Manufactured", format: "", dataType: "string", column: "mfd", width: "10%" },
        { dataField: "gameType", caption: "Type", format: "", dataType: "string", column: "gameType", width: "5%" },
        { dataField: "prchPrice", caption: "Price", format: "$#,##0.00", dataType: "number", column: "prchPrice", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "5%" },
        {}
      ],
    },
    {
      reportType: "DealsCompleted",
      columns: [
        { dataField: "locationId", caption: "Location", format: "", dataType: "string", column: "locationId", width: "5%" },
        { dataField: "locationName", caption: "Name", format: "", dataType: "string", column: "locationName", width: "10%" },
        { dataField: "gameName", caption: "Game", format: "", dataType: "string", column: "gameName", width: "10%" },
        { dataField: "dateInService", caption: "Date In Service", format: "", dataType: "string", column: "dateInService", width: "10%" },
        { dataField: "dateOutService", caption: "Date Closed", format: "", dataType: "string", column: "dateOutService", width: "10%" },
        { dataField: "serial", caption: "Deal Serial #", format: "", dataType: "string", column: "serial", width: "10%" },
        { dataField: "form", caption: "Form", format: "", dataType: "string", column: "form", width: "5%" },
        { dataField: "mfd", caption: "Manufactured", format: "", dataType: "string", column: "mfd", width: "10%" },
        { dataField: "gameType", caption: "Type", format: "", dataType: "string", column: "gameType", width: "5%" },
        { dataField: "tabsSold", caption: "Tabs Sold", format: "", dataType: "string", column: "tabsSold", width: "5%" },
        { dataField: "prchPrice", caption: "Price", format: "$#,##0.00", dataType: "number", column: "prchPrice", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "fpPercent", caption: "Final Payout %", format: function (fpPercent) { return fpPercent + "%"; }, dataType: "number", valueFormat: { type: "fixedPoint", precision: 2 }, column: "fpPercent", displayFormat: "{0}%", width: "10%" }
      ],
    }
  ];
  gameTerminalStatusColumns: any = [
    {
      reportType: "gameTerminalStatus",
      columns: [
        { dataField: "gtName", caption: "Terminal Name", format: "", dataType: "string", column: "gtName", width: "16.6%" },
        { dataField: "status", caption: "Status", format: "", dataType: "string", column: "status", width: "16.6%" },
        { dataField: "serialNumber", caption: "Serial", format: "", dataType: "string", column: "serialNumber", width: "16.6%" },
        { dataField: "locationId", caption: "Location ID", dataType: "string", column: "locationId", width: "16.6%" },
        { dataField: "created", caption: "Created", format: "", dataType: "string", column: "created", width: "16.6%" },
        { dataField: "lastUpdate", caption: "Last Update", format: "", dataType: "string", column: "lastUpdate", width: "16.6%" },
      ],
    },
  ];
  sessionsReportColumns = [
    { dataField: "locationId", caption: "Location", format: "", dataType: "string", column: "locationId", width: "10%" },
    { dataField: "locationName", caption: "Name", format: "", dataType: "string", column: "locationName", width: "20%" },
    { dataField: "sessionId", caption: "Session", format: "", dataType: "string", column: "sessionId", width: "5%" },
    { dataField: "charityId", caption: "Charity ID", format: "", dataType: "string", column: "charityId", width: "5%" },
    { dataField: "charityName", caption: "Charity Name", format: "", dataType: "string", column: "charityName", width: "20%" },
    { dataField: "sessionStart", caption: "Session Start", dataType: "datetime", column: "sessionStart", width: "10%", cellRender: renderSessionStartTime },
    { dataField: "sessionEnd", caption: "Session End", dataType: "datetime", column: "sessionEnd", width: "10%", cellRender: renderSessionEndTime },
    { caption: "Permitted Hours", allowSorting: false, width: "10%", calculateCellValue: getPermittedHoursValue },
    { dataField: "playedAmount", caption: "Played Amount", format: "$#,##0.00", dataType: "number", column: "playedAmount", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
  ];
  reconSummaryIPRReportColumns: any = [
    {
      columns: [
        { dataField: "lName", caption: "Location", format: "", dataType: "string", column: "lName", width: "20%" },
        { dataField: "lId", caption: "Location ID", format: "#", dataType: "number", column: "lId", valueFormat: "#", displayFormat: "{0}", width: "10%" },
        { dataField: "dateFrom", caption: "Date From", format: function (dateFrom) { return appUtilityService.formatTimeStampColumn(dateFrom) }, dataType: "date", column: "dateFrom", width: "10%" },
        { dataField: "dateTo", caption: "Date To", format: function (dateTo) { return appUtilityService.formatTimeStampColumn(dateTo) }, dataType: "date", column: "dateTo", width: "10%" },
        { dataField: "playCnt", caption: "Play Count", format: "#,##0", dataType: "number", column: "playCnt", valueFormat: "#,##0", displayFormat: "{0}", width: "10%" },
        { dataField: "cIn", caption: "Cash In", format: "$#,##0.00", dataType: "number", column: "cIn", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "cOut", caption: "Cash Out", format: "$#,##0.00", dataType: "number", column: "cOut", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "cNet", caption: "Cash Net", format: "$#,##0.00", dataType: "number", column: "cNet", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "played", caption: "Gross Play", format: "$#,##0.00", dataType: "number", column: "played", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "won", caption: "Won", format: "$#,##0.00", dataType: "number", column: "won", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "pwNet", caption: "Net Play", format: "$#,##0.00", dataType: "number", column: "pwNet", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "ppw", caption: "Payout", format: function (ppw) { return ppw + "%"; }, dataType: "number", valueFormat: { type: "fixedPoint", precision: 2 }, column: "ppw", displayFormat: "{0}%", width: "10%" },
        { dataField: "liability", caption: "Liability", format: "$#,##0.00", dataType: "number", column: "liability", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
      ],
      childColumns: [
        { dataField: "charityName", caption: "Charity", format: "", dataType: "string", column: "charityName", width: "25%" },
        { dataField: "sessionStart", caption: "Session Start", format: function (sessionStart) { return appUtilityService.formatTimeStampColumn(sessionStart) }, dataType: "date", column: "sessionStart", width: "10%" },
        { dataField: "sessionEnd", caption: "Session End", format: function (sessionEnd) { return appUtilityService.formatTimeStampColumn(sessionEnd) }, dataType: "date", column: "sessionEnd", width: "10%" },
        { dataField: "playCnt", caption: "Play Count", format: "#,##0", dataType: "number", column: "playCnt", valueFormat: "#,##0", displayFormat: "{0}", width: "10%" },
        { dataField: "cIn", caption: "Cash In", format: "$#,##0.00", dataType: "number", column: "cIn", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "cOut", caption: "Cash Out", format: "$#,##0.00", dataType: "number", column: "cOut", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "cNet", caption: "Cash Net", format: "$#,##0.00", dataType: "number", column: "cNet", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "played", caption: "Gross Play", format: "$#,##0.00", dataType: "number", column: "played", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "won", caption: "Won", format: "$#,##0.00", dataType: "number", column: "won", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "pwNet", caption: "Net Play", format: "$#,##0.00", dataType: "number", column: "pwNet", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" },
        { dataField: "ppw", caption: "Payout", format: function (ppw) { return ppw + "%"; }, dataType: "number", valueFormat: { type: "fixedPoint", precision: 2 }, column: "ppw", displayFormat: "{0}%", width: "5%" },
        { dataField: "liability", caption: "Liability", format: "$#,##0.00", dataType: "number", column: "liability", valueFormat: "$#,##0.00", displayFormat: "{0}", width: "10%" }
      ],
    },
  ];
  ohQuarterlyReportColumns = [
    { dataField: "locationId", caption: "Location ID", format: "#", dataType: "string", column: "locationId", valueFormat: "#", displayFormat: "{0}" },
    { dataField: "locationName", caption: "Location Name", format: "", dataType: "string", column: "locationName" },
    { dataField: "grossRevenue", caption: "Gross Revenue", format: "$#,##0.00", dataType: "number", column: "grossRevenue", valueFormat: "$#,##0.00", displayFormat: "{0}" },
    { dataField: "cashPrizes", caption: "Cash Prizes", format: "$#,##0.00", dataType: "number", column: "cashPrizes", valueFormat: "$#,##0.00", displayFormat: "{0}" },
    { dataField: "grossProfit", caption: "Gross Profit", format: "$#,##0.00", dataType: "number", column: "grossProfit", valueFormat: "$#,##0.00", displayFormat: "{0}" },
    { dataField: "creditsRedeemed", caption: "Credit Redeemed", format: "$#,##0.00", dataType: "number", column: "creditRedeemed", valueFormat: "$#,##0.00", displayFormat: "{0}" },
    { dataField: "numberOfDevices", caption: "Number Of Devices", format: "#,##0", dataType: "number", column: "numberOfDevices", valueFormat: "#,##0", displayFormat: "{0}" },
  ];
  ohElectronicInstantBingoReportColumns = [
    { dataField: "locationName", caption: "Location Name", format: "", dataType: "string", column: "locationName" },
    { dataField: "grossRevenue", caption: "Gross Revenue", format: "$#,##0.00", dataType: "number", column: "grossRevenue", valueFormat: "$#,##0.00", displayFormat: "{0}", headerCellRender: eibReportHeaderRender },
    { dataField: "cashPrizes", caption: "Cash Prizes", format: "$#,##0.00", dataType: "number", column: "cashPrizes", valueFormat: "$#,##0.00", displayFormat: "{0}", headerCellRender: eibReportHeaderRender },
    { dataField: "grossProfit", caption: "Gross Profit", format: "$#,##0.00", dataType: "number", column: "grossProfit", valueFormat: "$#,##0.00", displayFormat: "{0}", headerCellRender: eibReportHeaderRender },
    { dataField: "expensesBilledByVendor", caption: "Expenses Billed by Vendor", format: "$#,##0.00", dataType: "number", column: "expensesBilledByVendor", valueFormat: "$#,##0.00", displayFormat: "{0}", headerCellRender: eibReportHeaderRender },
    { dataField: "creditsRedeemed", caption: "Credits Redeemed", format: "$#,##0.00", dataType: "number", column: "creditsRedeemed", valueFormat: "$#,##0.00", displayFormat: "{0}", headerCellRender: eibReportHeaderRender },
    { dataField: "numberOfDevices", caption: "Number of Electronic Instant Bingo Devices (machines only)", format: "#,##0", dataType: "number", column: "numberOfDevices", valueFormat: "#,##0", displayFormat: "{0}" },
  ];
  adnwReportColumns = [
    { dataField: "locationId", caption: "Location ID", format: "#", dataType: "string", column: "locationId", visible: false },
    { dataField: "locationName", caption: "Location Name", format: "", dataType: "string", column: "locationName" },
    { dataField: "daysOpen", caption: "Days Open", format: "", dataType: "number", column: "daysOpen", width: "10%" },
    { dataField: "cabinets", caption: "Cabinets", format: "", dataType: "number", column: "cabinets", width: "10%" },
    { dataField: "tablets", caption: "Tablets", format: "", dataType: "number", column: "tablets", width: "10%" },
    { dataField: "totalTerminalCount", caption: "Total Installed", format: "", dataType: "number", column: "totalTerminalCount", width: "10%" },
    { dataField: "activeTerminalCount", caption: "Avg Active Term.", dataType: "number", column: "activeTerminalCount", width: "10%" },
    { dataField: "adnw", caption: "Avg Daily Net Win", format: "$#,##0.00", dataType: "number", column: "adnw", width: "10%" },
    { dataField: "adnwCabinets", caption: "ADNW (Cabinets)", format: "$#,##0.00", dataType: "number", column: "adnwCabinets", width: "10%" },
    { dataField: "adnwTablets", caption: "ADNW (Tablets)", format: "$#,##0.00", dataType: "number", column: "adnwTablets", width: "10%" },
  ];
  weeklyNumbersReportColumns = [
    { dataField: "csrZone", caption: "Csr Zone", format: "#", dataType: "string", column: "csrZone", visible: false },
    { dataField: "cid", caption: "CID", format: "#", dataType: "string", column: "cid", visible: false },
    { dataField: "locationName", caption: "Location Name", format: "", dataType: "string", column: "locationName" },
    { dataField: "cabinets", caption: "Cabinets", dataType: "number", column: "cabinets", width: "8%" },
    { dataField: "tablets", caption: "Tablets", format: "", dataType: "number", column: "tablets", width: "8%" },
    { dataField: "invoiceNo", caption: "Invoice No", format: "", dataType: "string", column: "invoiceNo", width: "8%" },
    { dataField: "cashIn", caption: "In", format: "$#,##0.00", dataType: "number", column: "cashIn", valueFormat: "$#,##0.00", displayFormat: "{0}" },
    { dataField: "cashOut", caption: "Out", format: "$#,##0.00", dataType: "number", column: "cashOut", valueFormat: "$#,##0.00", displayFormat: "{0}" },
    { dataField: "net", caption: "Net", format: "$#,##0.00", dataType: "number", column: "net", valueFormat: "$#,##0.00", displayFormat: "{0}" },
    { dataField: "totalCashNetDeduction", caption: "Charitable Gaming Fee Discount", format: "$#,##0.00", dataType: "number", column: "totalCashNetDeduction", valueFormat: "$#,##0.00", displayFormat: "{0}", visible: true, width: "8%" },
    { dataField: "subtotal", caption: "Subtotal", format: "$#,##0.00", dataType: "number", column: "subtotal", valueFormat: "$#,##0.00", displayFormat: "{0}", visible: false },
    { dataField: "credits", caption: "Credits", format: "$#,##0.00", dataType: "number", column: "credits", valueFormat: "$#,##0.00", displayFormat: "{0}", visible: false },
    { dataField: "contCredits", caption: "Cont", format: "$#,##0.00", dataType: "number", column: "contCredits", valueFormat: "$#,##0.00", displayFormat: "{0}", visible: false },
    { dataField: "promo", caption: "Promo", format: "$#,##0.00", dataType: "number", column: "promo", valueFormat: "$#,##0.00", displayFormat: "{0}", visible: false },
    { dataField: "stateSalesFee", caption: "State/Sales Fees", format: "$#,##0.00", dataType: "number", column: "stateSalesFee", valueFormat: "$#,##0.00", displayFormat: "{0}", visible: false },
    { dataField: "ticketsSoldFeeSalesTax", caption: "Tickets Sold Fee Tax", format: "$#,##0.00", dataType: "number", column: "ticketsSoldFeeSalesTax", valueFormat: "$#,##0.00", displayFormat: "{0}", visible: false },
    { dataField: "due", caption: "Due", format: "$#,##0.00", dataType: "number", column: "due", valueFormat: "$#,##0.00", displayFormat: "{0}", visible: false },
    { dataField: "hold", caption: "% Hold", format: "#,####0.0000", dataType: "number",valueFormat: "#,####0.0000", column: "hold", displayFormat: "{0}%", width: "5%" },
    { dataField: "avgCashIn", caption: "Avg Cash In", format: "$#,##0.00", dataType: "number", column: "avgCashIn", valueFormat: "$#,##0.00", displayFormat: "{0}", },
    { dataField: "distributor", caption: "Distributor", format: "#", dataType: "string", column: "distributor" },
    { dataField: "installDate", caption: "Install Date", format: function (installDate) { return appUtilityService.formatDate(installDate) }, dataType: "date", column: "installDate", visible: false },
    { dataField: "goLiveDate", caption: "Go Live Date", format: function (goLiveDate) { return appUtilityService.formatDate(goLiveDate) }, dataType: "date", column: "goLiveDate", visible: false },
    { dataField: "openPerSF", caption: "Open Per SF", format: "#,##0", dataType: "number", column: "openPerSF", valueFormat: "#,##0", displayFormat: "{0}" },
    { dataField: "actualOpen", caption: "Actual Open", format: "#,##0", dataType: "number", column: "actualOpen", valueFormat: "#,##0", displayFormat: "{0}", visible: false },
    { dataField: "hours", caption: "Hours", format: "#,##0.00", dataType: "number", column: "hours", valueFormat: "#,##0.00", displayFormat: "{0}", visible: false },
    { dataField: "avgNet", caption: "Avg Net", format: "$#,##0.00", dataType: "number", column: "avgNet", valueFormat: "$#,##0.00", displayFormat: "{0}" },
    { dataField: "cabinetDay", caption: "Cabinet Day", format: "$#,##0.00", dataType: "number", column: "cabinetDay", valueFormat: "$#,##0.00", displayFormat: "{0}", visible: false },
    { dataField: "fourWkAvgCabinetDay", caption: "4 Wk Avg Cabinet Day", format: "$#,##0.00", dataType: "number", column: "fourWkAvgCabinetDay", valueFormat: "$#,##0.00", displayFormat: "{0}", visible: false },
    { dataField: "cabinetHour", caption: "Cabinet Hour", format: "$#,##0.00", dataType: "number", column: "cabinetHour", valueFormat: "$#,##0.00", displayFormat: "{0}", visible: false },
    { dataField: "stateFeeDue", caption: "State Fee Due", format: "$#,##0.00", dataType: "number", column: "stateFeeDue", valueFormat: "$#,##0.00", displayFormat: "{0}", visible: false },
    { dataField: "totalNetTaxRate", caption: "Net Tax Rate", format: "#0.##%", dataType: "number", column: "totalNetTaxRate", valueFormat: "#0.##%", displayFormat: "{0}", visible: false },
    { dataField: "totalNetTaxRateValue", caption: "Net Tax", format: "$#,##0.00", dataType: "number", column: "totalNetTaxRateValue", valueFormat: "$#,##0.00", displayFormat: "{0}", visible: false },
    { dataField: "totalGrossTaxRate", caption: "Sales Tax Rate", format: "#0.##%", dataType: "number", column: "totalGrossTaxRate", valueFormat: "#0.##%", displayFormat: "{0}", visible: false },
    { dataField: "totalGrossTaxRateValue", caption: "Sales Tax", format: "$#,##0.00", dataType: "number", column: "totalGrossTaxRateValue", valueFormat: "$#,##0.00", displayFormat: "{0}", visible: true },
  ];
  saturdayReportColumns = {
    columns: [
      { dataField: "jurisdiction", caption: "Jurisdiction", dataType: "string" },
    ],
    childColumns: [
      { dataField: "locationId", caption: "ID", format: "", dataType: "string", visible: false },
      { dataField: "name", caption: "Hall", format: "", dataType: "string" },
      { dataField: "city", caption: "Hall City", format: "", dataType: "string" },
      { dataField: "salesRepresentative", caption: "Sales Representative", format: "", dataType: "string" },
    ],
  };
}
const appConstants = new AppConstants();

function renderSessionStartTime(item: { text: string, data: ISessionsListItem }) {
  return <span className={item.data.dateFromInPermittedHours ? '' : 't-red'}>{item.text}</span>;
}

function renderSessionEndTime(item: { text: string, data: ISessionsListItem }) {
  return <span className={item.data.dateToInPermittedHours ? '' : 't-red'}>{item.text}</span>;
}

function getPermittedHoursValue(
  { permittedHoursStart, permittedHoursEnd, permittedHoursWeekDay, sessionStart }: ISessionsListItem
) {
  if (permittedHoursWeekDay == null || permittedHoursStart == null || permittedHoursEnd == null) return 'not allowed';

  permittedHoursStart = moment(permittedHoursStart, 'HH:mm:ss.fff').format('hh:mm A');
  permittedHoursEnd = moment(permittedHoursEnd, 'HH:mm:ss.fff').format('hh:mm A');

  const sessionWeekDay = (moment(sessionStart).isoWeekday() % 7) + 1;

  const weekDayPrefix = `${formatShortWeekDay(permittedHoursWeekDay)}: `;
  return `${sessionWeekDay !== permittedHoursWeekDay ? weekDayPrefix : ''}${permittedHoursStart} - ${permittedHoursEnd}`;
}

function formatShortWeekDay(value: number) {
  switch (value) {
    case 1: return 'Sun';
    case 2: return 'Mon';
    case 3: return 'Tue';
    case 4: return 'Wed';
    case 5: return 'Thu';
    case 6: return 'Fri';
    case 7: return 'Sat';
    default: return '';
  }
}

export default appConstants;
