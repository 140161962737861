import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as UserAccountActions from '../../actions/user/UserAccountActions';
import LoginComponent from '../../components/user/LoginComponent';
import { RootState } from '../../store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
    return {
        userLoginSuccess: state.UserAccountReducer.userLoginSuccess,
        userLoginError: state.UserAccountReducer.userLoginError,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(UserAccountActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent)
