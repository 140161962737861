import * as React from "react";
import WebAccessService from "../../services/WebAccessService";
import { Card, Row, Col, Button } from 'antd';

export class UnauthorizedAccessComponent extends React.Component<{}, {}> {
    constructor(props: any) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Row>
                <Col span={24}>
                    <Card title="Unauthorized access">
                        <h2>Access to this place or content is restricted. If you think this is a mistake, please contact your administrator or the person who directed you here.</h2>
                        <Button onClick={this.resetPermisisons}>Back</Button>
                    </Card>
                </Col>
            </Row>
        );
    }

    resetPermisisons = () => {
        WebAccessService.resetSidebarFeatures();
    }

}