import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as UserAccountActions from '../../actions/user/UserAccountActions';
import ResetPasswordComponent from '../../components/user/ResetPasswordComponent';
import { RootState } from '../../store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
    return {
        userResetPasswordSuccess: state.UserAccountReducer.userResetPasswordSuccess,
        userResetPasswordError: state.UserAccountReducer.userResetPasswordError,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(UserAccountActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordComponent)
