import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as UserActions from '../../actions/user/UserActions';
import { UserDetailsDescriptionCardComponent } from '../../components/user/UserDetailsDescriptionCardComponent';
import { RootState } from '../../../common/store/ConfigureStore';

const mapStateToProps = (state: RootState) => {
  return {
    updateUserDetailsSuccess: state.UserReducer.updateUserDetailsSuccess,
    updateUserDetailsError: state.UserReducer.updateUserDetailsError,

    deleteUserSuccess: state.UserReducer.deleteUserSuccess,
    deleteUserError: state.UserReducer.deleteUserError,

    uploadUserImageSuccess: state.UserReducer.uploadUserImageSuccess,
    uploadUserImageError: state.UserReducer.uploadUserImageError,

    removeUserImageSuccess: state.UserReducer.removeUserImageSuccess,
    removeUserImageError: state.UserReducer.removeUserImageError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(UserActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsDescriptionCardComponent)
