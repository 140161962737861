import * as React from "react";
import { isEqual } from "lodash";
import { FormComponentProps } from 'antd/lib/form/Form'

import AppUtilityService from "../../services/AppUtilityService";

import { Row, Col, Card, Form, Icon, Input, Button, Spin } from 'antd';
import { Link, hashHistory } from "react-router";
import sessionService from "../../services/SessionService";
import webAccessService from "../../services/WebAccessService";
const FormItem = Form.Item;

class ResetPasswordComponent extends React.Component<IUserResetPasswordProps & FormComponentProps, IUserResetPasswordState> {
    constructor(props: IUserResetPasswordProps & FormComponentProps) {
        super(props);
        this.state = {
            confirmDirty: false,
            isLoading: false
        }
    }

    componentDidMount() {
        if (sessionService.loggedIn()) {
            webAccessService.resetSidebarFeatures();
        }
    }

    componentWillReceiveProps(nextProps: IUserResetPasswordProps) {
        /**
         * Get user details success/error handle
         */
        if (nextProps.userResetPasswordSuccess && !isEqual(this.props.userResetPasswordSuccess, nextProps.userResetPasswordSuccess)) {
            hashHistory.push("/login");
            AppUtilityService.showNotificationToast("Password changed", "Password has been changed successfully", "success");
            this.setState({ confirmDirty: true, isLoading: false });
        }

        if (nextProps.userResetPasswordError && !isEqual(this.props.userResetPasswordError, nextProps.userResetPasswordError)) {
            AppUtilityService.showMessageToast(nextProps.userResetPasswordError.response.data, "error");
            this.setState({ confirmDirty: true, isLoading: false });
        }
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Spin spinning={this.state.isLoading}>
                <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
                  <Col xs={{ span: 24 }} sm={{ span: 16 }} md={{ span: 16 }} lg={{ span: 10 }} xl={{ span: 6 }}>
                        <Card title="Reset Password" bordered={false}>
                            <Form onSubmit={this.handleFormSubmit}>
                                <FormItem label="Password">
                                    {getFieldDecorator('password', {
                                        rules: [
                                            { required: true, message: 'Please enter a new password!' },
                                            {
                                                validator: this.validateToNextPassword,
                                            },
                                        ],
                                    })(
                                        <Input prefix={<Icon type="lock" />} type="password" placeholder="New Password" />
                                    )}
                                </FormItem>
                                <FormItem label="Confirm Password">
                                    {getFieldDecorator('confirmPassword', {
                                        rules: [
                                            { required: true, message: 'Please confirm password!' },
                                            {
                                                validator: this.compareToFirstPassword,
                                            }
                                        ],
                                    })(
                                        <Input prefix={<Icon type="lock" />} type="password" placeholder="Confirm Password" onBlur={this.handleConfirmBlur} />
                                    )}
                                </FormItem>
                                <FormItem>
                                    <div>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Reset
                                    </Button>
                                        <br />
                                        <Link to="/login" className="btn btn-link">Back to Login</Link>
                                    </div>
                                </FormItem>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Spin>
        );
    }

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value, isLoading: false });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirmPassword'], { force: true });
        }
        callback();
    };

    handleFormSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ confirmDirty: true, isLoading: true });
                var formData = values;
                formData.token = this.props.params && this.props.params.token || null;
                this.props.actions.resetPassword(formData);
            }
        });
    }
} export default Form.create<IUserResetPasswordProps & FormComponentProps>()(ResetPasswordComponent);