import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import useBreadcrumbs from '../../../common/hooks/useBreadcrumbs';
import { Card, Dropdown, Icon, Layout, Menu, message } from 'antd';
import TicketSalesByGameReportFilters, { Filters } from './TicketSalesByGameReportFilters';
import TicketSalesByGameReport from './TicketSalesByGameReport';
import { getTicketSalesByGameReport } from '../../actions/reporting/getTicketSalesByGameReport';
import { LoadState } from '../../../common/store/fetched';
import { downloadExcelTicketSalesReport } from '../../actions/reporting/downloadExcelTicketSalesReport';
import WebAccessService from '../../../common/services/WebAccessService';
import { downloadPdfTicketSalesByGameReport } from '../../actions/reporting/downloadPdfTicketSalesByGameReport';

const TicketSalesByGameReportPage = () => {
  const dispatch = useAppDispatch();

  const [filters, setFilters] = useState<Filters>({
    startDate: moment().subtract(1, 'week').startOf('week'),
    endDate: moment().subtract(1, 'week').endOf('week'),
  });

  useBreadcrumbs([
    { name: 'Reports', link: '/Reports' },
    { name: 'Ticket Sales By Game', link: null },
  ]);

  const isDownloadingInProgress = useAppSelector(
    (state) =>
      state.ReportingReducer.downloadExcelTicketSalesByGameReportUpdated.loadState === LoadState.InProgress
  );
  const hasDownloadingFailed = useAppSelector(
    (state) => state.ReportingReducer.downloadExcelTicketSalesByGameReportUpdated.loadState === LoadState.Failed
  );

  const isLoadingList = useAppSelector(
    (state) => state.ReportingReducer.ticketSalesByGameReportUpdated.loadState === LoadState.InProgress);

 const isLoadingChildList = useAppSelector(
    (state) => state.ReportingReducer.ticketSalesByGameReportChildUpdated.loadState === LoadState.InProgress);

  useEffect(() => {
    if (hasDownloadingFailed) {
      message.error('Failed to download report');
    }
  }, [hasDownloadingFailed]);

  const downloadExcel = () => {
    dispatch(downloadExcelTicketSalesReport(
      filters.startDate,
      filters.endDate,
      "game",
      filters.jurisdictionId,
      filters.siteId,
      filters.organizationId,
    ));
  };

  const downloadPdf = () => {
    dispatch(downloadPdfTicketSalesByGameReport(
      ['breakdownByGameChart1', 'breakdownByGameChart2'],
      filters.startDate,
      filters.endDate,
      filters.jurisdictionId,
      filters.siteId,
      filters.organizationId,
    ));
  };

  useEffect(
    () => {
      dispatch(getTicketSalesByGameReport(
        filters.startDate,
        filters.endDate,
        filters.jurisdictionId,
        filters.siteId,
        filters.organizationId,
      ));
    },
    [dispatch, filters]);

    const extraActions = (
      <div>
        <Dropdown.Button
          type="primary"
          onClick={downloadExcel}
          disabled={ isDownloadingInProgress || isLoadingList || isLoadingChildList }
          overlay={
            <Menu>
              <Menu.Item onClick={downloadExcel}>Excel</Menu.Item>
              <Menu.Item onClick={downloadPdf}>PDF</Menu.Item>
            </Menu>}>
          <Icon type="download" style={{ fontSize: "16px" }}/> Download as Excel
        </Dropdown.Button>
      </div>
    );

  return (
    <>
      <Card
        title="Ticket Sales By Game"
        style={{ paddingTop: "12px" }}
        className="reports-filter-card content-card no-header-border"
        bordered={false}
        extra={WebAccessService.hasPermissionToAccess("Reports", "Download") ? extraActions : null}
      >
        <TicketSalesByGameReportFilters filters={filters} applyFilters={setFilters}/>
      </Card>
      <div className="pg-reports-container">
        <Layout>
          <div className="container" style={{ background: '#fff' }}>
            <TicketSalesByGameReport filters={filters} />
          </div>
        </Layout>
      </div>
    </>
  );
}

export default TicketSalesByGameReportPage;
